@font-face {
    font-family: "Recoleta";
    src: url(Fonts/Recoleta-Regular.ttf);
}

@font-face {
    font-family: "Proxima Nova";
    src: url(Fonts/ProximaNova-Regular.ttf);
}

.shape {
    width: 400px;
    font-size: 18px;
    line-height: 24px;
    margin-left: auto;
    margin-right: auto;
}

.portfolio-card {
    width: 81%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-top: 30px;
}

.port-card {
    width: 95%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: 430px;
    margin-left: auto;
    margin-right: auto;
}

.port-img {
    width: 100%;
    height: 400px;
    background: url("../images/icons/Cucumislush.png");
    background-repeat: no-repeat;
}

.port-img1 {
    width: 100%;
    height: 400px;
    background: url("../images/icons/Tracnine.png");
    background-repeat: no-repeat;
}

.port-img2 {
    width: 100%;
    height: 400px;
    background: url("../images/icons/Fixit.png");
    background-repeat: no-repeat;
}

.port-img3 {
    width: 100%;
    height: 400px;
    background: url("../images/icons/Melon.png");
    background-repeat: no-repeat;
}

.media {
    font-size: 16px;
}


/* Media Query */

@media screen and (max-width: 480px) {
    .shape {
        font-size: 14px;
        line-height: 16px;
        width: 280px;
        margin-left: 0px;
        margin-right: 0px;
    }
    .portfolio-card {
        width: 85%;
        grid-template-columns: repeat(1, 1fr);
        margin-top: 20px;
        margin-bottom: -30px;
    }
    .port-card {
        width: 97%;
        gap: 10px;
        height: 360px;
    }
    .port-img,
    .port-img1,
    .port-img2,
    .port-img3 {
        height: 230px;
    }
    .media {
        font-size: 14px;
        line-height: 18px;
    }
}

@media screen and (min-width: 481px) and (max-width: 767px) {
    .portfolio-card {
        width: 85%;
        grid-template-columns: repeat(1, 1fr);
        margin-top: 20px;
        margin-bottom: -20px;
    }
    .port-card {
        width: 97%;
        gap: 10px;
    }
    .port-img,
    .port-img1,
    .port-img2,
    .port-img3 {
        height: 300px;
    }
    .media {
        font-size: 14px;
        line-height: 18px;
    }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    .portfolio-card {
        width: 88%;
    }
    .port-card {
        width: 97%;
        gap: 10px;
    }
    .port-img,
    .port-img1,
    .port-img2,
    .port-img3 {
        height: 300px;
    }
    .media {
        font-size: 14px;
        line-height: 18px;
    }
}

@media screen and (min-width:992px) and (max-width: 1140px) {
    .portfolio-card {
        width: 90%;
    }
    .port-card {
        width: 98%;
    }
}

@media (min-width: 1400px) {
    .media {
        font-size: 18px;
    }
}