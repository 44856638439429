/*General Styling*/

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html,
body {
    width: 100%;
    height: auto;
    overflow-x: hidden;
    scroll-behavior: smooth;
}


/*End of General Style*/