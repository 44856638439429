@font-face {
    font-family: "Recoleta";
    src: url(Fonts/Recoleta-Regular.ttf);
}

@font-face {
    font-family: "Proxima Nova";
    src: url(Fonts/ProximaNova-Regular.ttf);
}

.faq-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 80px;
    margin-bottom: 50px;
}

.faq-head {
    width: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    gap: 20px;
}

.faq-title {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    color: #777777;
}

.faq-header {
    font-family: 'Recoleta';
    font-style: normal;
    font-weight: 700;
    font-size: 45px;
    line-height: 60px;
    text-align: center;
    color: #242424;
}

.faq-header span,
.faq-sub span {
    color: #214aa0;
}

.faq-sub {
    width: 560px;
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    text-align: center;
    color: #242424;
}


/* FAQ BODY */

.faq-body {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    height: auto;
    border: 0.5px solid #efefef;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    padding-top: 80px;
    padding-bottom: 80px;
    position: relative;
}


/*FAQ Nav */

.faq-nav {
    position: absolute;
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 20px;
    padding-left: 40px;
    padding-right: 40px;
    top: -35px;
    border: 0.5px solid #efefef;
    border-radius: 8px;
    height: 70px;
    background: #ffffff;
    box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.08);
}

.faq-nav-head {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 25px;
    color: #242424;
}

.faq-menu {
    display: flex;
    flex-direction: row;
    gap: 30px;
}

.sub-menu {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #D9D9D9;
    text-align: center;
    cursor: pointer;
}

.activate {
    color: #214aa0;
    height: 30px;
    width: 95px;
    border-bottom: 1px solid #fdc329;
    cursor: default;
}


/* FAQ Questions */

.question {
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: 30px;
    height: auto;
    padding-left: 40px;
    padding-right: 30px;
}

.faq-question {
    display: flex;
    flex-direction: column;
    height: auto;
    padding-bottom: 20px;
    border: 0.3px solid #efefef;
    padding-top: 20px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.04);
}

.question-title {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #777777;
    cursor: default;
    border-bottom: 1px solid #efefef;
    width: 100%;
    padding-left: 24px;
    padding-bottom: 20px;
}

.question-body {
    padding-left: 24px;
    padding-top: 10px;
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    color: #777777;
}

#faq-color {
    color: #242424;
    font-weight: 600;
}


/* Media Query */

@media (max-width: 480px) {
    .faq-container {
        gap: 60px;
    }
    .faq-header {
        font-size: 20px;
        line-height: 25px;
    }
    .faq-sub {
        font-size: 14px;
        line-height: 18px;
        width: 300px;
    }
    .faq-body {
        width: 98%;
        padding-top: 50px;
    }
    .question {
        width: 100%;
        padding-left: 10px;
        padding-right: 10px;
    }
    .faq-nav {
        width: 95%;
        height: 60px;
        top: -30px;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 20px;
    }
    .faq-menu {
        gap: 20px;
    }
    .faq-nav-head,
    .sub-menu {
        font-size: 16px;
        line-height: 20px;
    }
    .activate {
        width: 65px;
    }
    .question-title {
        padding-left: 8px;
        font-size: 16px;
    }
    .question-body {
        padding-left: 8px;
        font-size: 14px;
    }
}

@media (min-width: 481px) and (max-width: 767px) {
    .faq-header {
        font-size: 32px;
        line-height: 40px;
    }
    .faq-sub {
        width: 400px;
    }
    .faq-body {
        width: 95%;
    }
    .question {
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
    }
    .faq-nav {
        width: 90%;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 25px;
    }
    .faq-menu {
        gap: 15px;
    }
    .faq-nav-head,
    .sub-menu {
        font-size: 18px;
        line-height: 20px;
    }
    .activate {
        width: 85px;
    }
    .question-title {
        padding-left: 14px;
        font-size: 18px;
    }
    .question-body {
        padding-left: 14px;
        font-size: 14px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .faq-header {
        font-size: 42px;
        line-height: 50px;
    }
    .faq-body {
        width: 90%;
    }
    .question {
        width: 95%;
    }
    .faq-nav {
        width: 85%;
    }
}