@font-face {
  font-family: "Recoleta";
  src: url(Fonts/Recoleta-Regular.ttf);
}

@font-face {
  font-family: "Proxima Nova";
  src: url(Fonts/ProximaNova-Regular.ttf);
}
/* Container */

.container {
  position: relative;
  width: 100%;
  height: 640px;
  background: #214aa0;
  scroll-behavior: smooth;
}
/*Nav Styling*/

.nav-bar {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80px;
  background: #214aa0;
  position: fixed;
  top: 0;
  z-index: 1000;
  /* overflow-y: hidden; */
}

.one {
  display: none;
}
/* Icon Container */

.mask {
  width: 13%;
  height: 60px;
  margin-left: 70px;
  margin-right: 200px;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin-top: 0px;
  background-size: contain;
}

.logo {
  position: relative;
  width: 151px;
  height: 60px;
  background: url("../images/conclase.png");
  background-repeat: no-repeat;
  background-size: contain;
}

.logo:hover,
.logo-1:hover {
  transform: scale(0.85);
  transition: 0.5s ease;
}

.logo-1 {
  position: relative;
  width: 133px;
  height: 60px;
  background: url("../images/conclase3.png");
  background-repeat: no-repeat;
  background-size: contain;
}
/*Links Container */

.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  width: 58%;
  height: 50px;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.links {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 7%;
  width: 72%;
  height: 35px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.nav-link,
.nav-link1,
.nav-link2,
.nav-link3,
.nav-link4,
.nav-link5 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 8px;
  gap: 6%;
  height: 35px;
  flex: none;
  flex-grow: 0;
}

.nav-link {
  width: 10.5%;
  order: 0;
}

.nav-link1 {
  width: 10.5%;
  order: 1;
}

.nav-link2 {
  width: 12%;
  order: 2;
}

.nav-link3 {
  width: 13%;
  order: 3;
}

.nav-link4 {
  width: 13%;
  order: 4;
}

.nav-link5 {
  width: 11%;
  order: 5;
}
/* Link Text Container */

.link-text,
.link-text1,
.link-text2,
.link-text3,
.link-text4,
.link-text5,
.link-text6 {
  text-decoration: none;
  height: 20px;
  font-family: "Proxima Nova";
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.link-text {
  width: 73%;
  color: #ffffff;
}

.link-text1 {
  width: 73%;
  font-weight: 400;
  color: #ffffff;
}

.link-text2 {
  font-weight: 400;
  width: 78%;
  color: #ffffff;
}

.link-text3 {
  font-weight: 400;
  width: 79%;
  color: #ffffff;
}

.link-text4 {
  font-weight: 400;
  width: 80%;
  color: #ffffff;
}

.link-text5 {
  font-weight: 400;
  width: 75%;
  color: #ffffff;
}

.link-text6 {
  font-weight: 600;
  color: #242424;
}

.link-text:hover,
.link-text1:hover,
.link-text2:hover,
.link-text3:hover,
.link-text4:hover,
.link-text5:hover {
  color: #fdc329;
  font-size: 20px;
  animation: zoomIn;
  animation-duration: 2s;
  animation-delay: -0.8s;
  animation-iteration-count: 1;
}

#state {
  font-weight: 600;
  border-bottom: 2px solid #fdc329;
}

#active {
  cursor: default;
}

#active:hover {
  cursor: default;
  animation: none;
  font-size: 16px;
  color: #ffffff;
}

.nav-link1,
.nav-link3 {
  display: flex;
  flex-direction: column;
  gap: 15px;
  cursor: pointer;
  height: 50px;
}

.nav-link1 ul,
.nav-link3 ul {
  width: 180px;
  height: 220px;
  display: none;
  background-color: #ffffff;
  list-style-type: none;
  margin-left: -20px;
  padding-top: 5px;
  padding-bottom: 5px;
  z-index: 500;
  box-shadow: 0px 1px 24px 8px rgba(20, 20, 20, 0.08);
}

.nav-link3 ul {
  width: 220px;
}

.nav-link1 ul li,
.nav-link3 ul li {
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  font-size: 14px;
  color: #242424;
  height: 45px;
  padding-left: 10px;
  font-family: "Proxima Nova";
  padding-top: 15px;
  text-decoration: none;
  margin-bottom: 2px;
  background-color: #ffffff;
}

.nav-link1 ul li a,
.nav-link3 ul li a {
  text-decoration: none;
  color: #242424;
  padding-top: 10px;
  padding-bottom: 15px;
  padding-right: 35px;
  font-weight: 400;
}

.nav-link1:hover span,
.nav-link3:hover span {
  color: #fdc329;
  font-size: 20px;
  animation: zoomIn;
  animation-duration: 2s;
  animation-delay: -0.8s;
  animation-iteration-count: 1;
}

.nav-link1:hover ul,
.nav-link3:hover ul {
  display: block;
}

.nav-link1 ul li:hover,
.nav-link3 ul li:hover {
  background-color: #e7e7e7;
  border-radius: 2px;
  transition: 0.5s ease;
}

.nav-link1 ul li:hover a,
.nav-link3 ul li:hover a {
  font-size: 17px;
  transition: 0.4s ease;
  font-weight: 700;
}
/* Contact Button */

.contact {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 1% 4%;
  width: 18.5%;
  height: 52px;
  background: #fdc329;
  border-radius: 8px;
  flex: none;
  order: 1;
  flex-grow: 0;
  cursor: pointer;
  text-decoration: none;
}

.contact:hover {
  transform: scale(1.05);
  transition: 0.5s ease;
}
/*End of Nav Styling*/
/* Content Styling */

.contain {
  display: flex;
  flex-direction: row;
  gap: 5%;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  margin-top: 10px;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0px;
  gap: 10px;
  position: relative;
  width: 50%;
  height: 420px;
  margin-left: 9%;
  top: 110px;
}

.main-content {
  width: 490px;
  height: 200px;
  font-family: "Recoleta";
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 60px;
  color: #ffffff;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.main-content span {
  animation: 1s ease;
  font-family: cursive;
}

#robo {
  font-family: "Recoleta";
}

.content-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0px;
  gap: 40px;
  width: 100%;
  height: 150px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.sub-content {
  width: 480px;
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #ffffff;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.btn-content {
  display: flex;
  flex-direction: row;
  gap: 7%;
  order: 2;
}

.content-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 81%;
  height: 60px;
  background: #fdc329;
  border-radius: 8px;
  flex: none;
  order: 0;
  flex-grow: 0;
  text-decoration: none;
  cursor: pointer;
}

.content-btn:hover,
.content-btn-1:hover {
  transform: scale(0.9);
  transition: 0.3s ease;
}

.content-btn-1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 5%;
  width: 81%;
  height: 60px;
  border: 2px solid #fdc329;
  border-radius: 8px;
  flex: none;
  order: 1;
  flex-grow: 0;
  text-decoration: none;
  cursor: pointer;
}

.btn-img {
  width: 24px;
  height: 25px;
  background: url("../images/join.png");
  background-repeat: no-repeat;
}

.content-link {
  height: 25px;
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 25px;
  color: #242424;
  flex: none;
  order: 0;
  flex-grow: 0;
  cursor: pointer;
}

.change {
  color: #fdc329;
}
/* Curved Text*/

.rotate {
  position: absolute;
  top: 80px;
  left: 70.5%;
}

.rotate span {
  position: absolute;
  color: transparent;
  font-family: "Proxima Nova";
  font-size: 37px;
  font-weight: 200;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: whitesmoke;
}
/* World Image Styling */

.image {
  position: absolute;
  width: 400px;
  height: 380px;
  top: 115px;
  left: 53.5%;
  background: url("../images/world1.png");
  background-size: contain;
  background-repeat: no-repeat;
}
/* End of Container */
/* Responsive Design */

@media (max-width: 991px) {
  .logo:hover,
  .logo-1:hover {
    transform: scale(1);
  }
  .link-text1:hover,
  .link-text2:hover,
  .link-text3:hover,
  .link-text4:hover,
  .link-text5:hover,
  .link-text:hover {
    color: #ffffff;
    font-size: 16px;
    animation: none;
  }
  .nav-link,
  .nav-link1,
  .nav-link2,
  .nav-link3,
  .nav-link4 {
    width: 100px;
  }
  .nav-link1:hover span,
  .nav-link3:hover span {
    color: #ffffff;
    font-size: 16px;
    animation: none;
  }
  .nav-link1 ul li a,
  .nav-link3 ul li a {
    padding-right: 10px;
  }
  .contact:hover {
    transform: scale(1);
    transition: 0.5s ease;
  }
  .cancel {
    font-size: 24px;
  }
  .nav-link1 ul li:hover,
  .nav-link3 ul li:hover {
    background: transparent;
    border-radius: 0px;
  }
}

@media (max-width: 480px) {
  .container {
    height: 860px;
  }
  .mask {
    margin-left: 30px;
  }
  .logo,
  .logo-1 {
    width: 120px;
    height: 50px;
    margin-top: 15px;
  }
  .nav-bar {
    align-items: flex-start;
    justify-content: space-between;
    transition: 0.2s height ease-in-out;
  }
  .expanded {
    height: 100vh;
    width: 100%;
    background-color: #214aa0;
    display: flex;
    justify-content: space-between;
    overflow-y: scroll;
  }
  .expanded::-webkit-scrollbar {
    display: none;
  }
  .one {
    display: block;
    position: absolute;
    top: 20px;
    right: 30px;
    cursor: pointer;
  }
  .mobile-btn {
    width: 40px;
    height: 40px;
    background: url("../images/design/mobilebtn.png");
    background-size: contain;
    background-repeat: no-repeat;
  }
  .mobile-btn-1 {
    width: 40px;
    height: 40px;
    background: url("../images/design/mobilebtn1.png");
    background-size: contain;
    background-repeat: no-repeat;
  }
  .nav-bar .wrapper {
    display: none;
  }
  .expanded .wrapper {
    position: absolute;
    left: 0;
    width: 100%;
    display: flex;
    gap: 10px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: 30px;
  }
  .links {
    flex-direction: column;
    align-items: flex-start;
    margin-left: 0px;
    margin-top: 100px;
    height: auto;
    gap: 10px;
    width: 100%;
  }
  #state {
    border-bottom: 0px;
  }
  .nav-link,
  .nav-link2,
  .nav-link3,
  .nav-link4 {
    height: 40px;
  }
  .nav-link1,
  .nav-link3 {
    height: auto;
  }
  .contact {
    width: 25%;
  }
  .mobile-contact {
    width: 100px;
    padding: 0 7px;
    background: none;
    height: 40px;
    align-items: flex-start;
    justify-content: flex-start;
    margin-left: 0px;
  }
  .link-text6 {
    font-weight: 400;
    color: #242424;
  }
  .nav-link1 ul {
    height: 150px;
    margin-left: -15px;
    padding-top: 0px;
    background-color: transparent;
  }
  .nav-link3 ul {
    height: 110px;
    margin-left: -15px;
    padding-top: 0px;
    background-color: transparent;
  }
  .nav-link1 ul li,
  .nav-link3 ul li {
    color: #f8f8f8;
    background-color: transparent;
    height: 30px;
    margin-bottom: 10px;
    padding-top: 0px;
    font-size: 16px;
  }
  #newcolor1 {
    color: #777777;
  }
  #newcolor2 {
    color: #ffffff;
  }
  /*End of Nav */
  .content {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    top: 50px;
    gap: 20px;
  }
  .main-content {
    width: 290px;
    font-size: 28px;
    line-height: 40px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    height: auto;
  }
  .content-text {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    gap: 20px;
  }
  .sub-content {
    width: 270px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    text-align: center;
    font-size: 14px;
    line-height: 20px;
  }
  .btn-content {
    width: 80%;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
  }
  .content-btn,
  .content-btn-1 {
    width: 100%;
    height: 50px;
    margin-bottom: 10px;
  }
  .content-link {
    font-size: 14px;
  }
  .rotate {
    left: 50%;
    right: auto;
    top: 490px;
  }
  .rotate span {
    font-size: 28px;
  }
  .image {
    left: 10%;
    top: 520px;
    width: 270px;
    height: 310px;
  }
}

@media (min-width: 350px) and (max-width: 389px) {
  .image {
    left: 14%;
  }
  .sub-content {
    width: 290px;
  }
}

@media (min-width: 390px) and (max-width: 424px) {
  .image {
    left: 18%;
  }
}

@media (min-width: 425px) and (max-width: 459px) {
  .image {
    left: 20%;
  }
  .sub-content {
    width: 290px;
  }
}

@media (min-width: 460px) and (max-width: 480px) {
  .image {
    left: 22%;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  .container {
    height: 900px;
  }
  .mask {
    margin-left: 30px;
  }
  .logo,
  .logo-1 {
    width: 120px;
    height: 50px;
    margin-top: 15px;
  }
  .nav-bar {
    align-items: flex-start;
    justify-content: space-between;
    transition: 0.2s height ease-in-out;
  }
  .expanded {
    height: 100vh;
    width: 100%;
    background-color: #214aa0;
    display: flex;
    justify-content: space-between;
    overflow-y: scroll;
  }
  .expanded::-webkit-scrollbar {
    display: none;
  }
  .one {
    display: block;
    position: absolute;
    top: 20px;
    right: 30px;
    cursor: pointer;
  }
  .mobile-btn {
    width: 40px;
    height: 40px;
    background: url("../images/design/mobilebtn.png");
    background-size: contain;
    background-repeat: no-repeat;
  }
  .mobile-btn-1 {
    width: 40px;
    height: 40px;
    background: url("../images/design/mobilebtn1.png");
    background-size: contain;
    background-repeat: no-repeat;
  }
  .nav-bar .wrapper {
    display: none;
  }
  .expanded .wrapper {
    position: absolute;
    left: 0;
    width: 100%;
    display: flex;
    gap: 10px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: 30px;
  }
  .links {
    flex-direction: column;
    align-items: flex-start;
    margin-left: 0px;
    margin-top: 100px;
    height: auto;
    gap: 10px;
    width: 100%;
  }
  #state {
    border-bottom: 0px;
  }
  .nav-link,
  .nav-link2,
  .nav-link3,
  .nav-link4 {
    height: 40px;
  }
  .nav-link1,
  .nav-link3 {
    height: auto;
  }
  .contact {
    width: 25%;
  }
  .mobile-contact {
    width: 100px;
    padding: 0 7px;
    background: none;
    height: 40px;
    align-items: flex-start;
    justify-content: flex-start;
    margin-left: 0px;
  }
  .link-text6 {
    font-weight: 400;
  }
  .nav-link1 ul,
  .nav-link3 ul {
    height: 150px;
    margin-left: -15px;
    padding-top: 0px;
    background-color: transparent;
  }
  .nav-link1 ul li,
  .nav-link3 ul li {
    color: #f8f8f8;
    background-color: transparent;
    height: 30px;
    margin-bottom: 10px;
    padding-top: 0px;
    font-size: 16px;
  }
  #newcolor1 {
    color: #777777;
  }
  #newcolor2 {
    color: #ffffff;
  }
  /*End of Nav */
  .content {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    top: 50px;
    gap: 20px;
  }
  .main-content {
    width: 80%;
    font-size: 28px;
    line-height: 40px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    height: auto;
  }
  .content-text {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    gap: 20px;
  }
  .sub-content {
    width: 98%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    text-align: center;
    font-size: 16px;
  }
  .btn-content {
    width: 75%;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
  }
  .content-btn,
  .content-btn-1 {
    width: 100%;
    height: 50px;
    margin-bottom: 10px;
  }
  .content-link {
    font-size: 14px;
  }
  .rotate {
    left: 50%;
    right: auto;
    top: 480px;
  }
  .image {
    left: 18%;
    top: 520px;
    width: 370px;
    height: 310px;
  }
}

@media (min-width: 520px) and (max-width: 549px) {
  .image {
    left: 21%;
  }
}

@media (min-width: 550px) and (max-width: 767px) {
  .main-content {
    font-size: 32px;
    width: 400px;
  }
  .image {
    left: 22%;
  }
}

@media (min-width: 590px) and (max-width: 767px) {
  .image {
    left: 24%;
  }
}

@media (min-width: 630px) and (max-width: 767px) {
  .image {
    left: 26%;
  }
}

@media (min-width: 680px) and (max-width: 767px) {
  .image {
    left: 28%;
  }
}

@media (min-width: 750px) and (max-width: 767px) {
  .image {
    left: 29.5%;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .container {
    height: 880px;
  }
  .nav-bar {
    align-items: flex-start;
    justify-content: space-between;
    transition: 0.2s height ease-in-out;
  }
  .expanded {
    height: 100vh;
    width: 100%;
    background-color: #214aa0;
    display: flex;
    justify-content: space-between;
    overflow-y: scroll;
  }
  .expanded::-webkit-scrollbar {
    display: none;
  }
  .logo,
  .logo-1 {
    margin-top: 10px;
  }
  .one {
    display: block;
    position: absolute;
    top: 20px;
    right: 70px;
    cursor: pointer;
  }
  .mobile-btn {
    width: 40px;
    height: 40px;
    background: url("../images/design/mobilebtn.png");
    background-size: contain;
    background-repeat: no-repeat;
  }
  .mobile-btn-1 {
    width: 40px;
    height: 40px;
    background: url("../images/design/mobilebtn1.png");
    background-size: contain;
    background-repeat: no-repeat;
  }
  .nav-bar .wrapper {
    display: none;
  }
  .expanded .wrapper {
    position: absolute;
    left: 0;
    width: 100%;
    display: flex;
    gap: 10px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: 30px;
  }
  .links {
    flex-direction: column;
    align-items: flex-start;
    margin-left: 0px;
    margin-top: 100px;
    height: auto;
    gap: 10px;
    width: 100%;
  }
  #state {
    border-bottom: 0px;
  }
  .nav-link,
  .nav-link2,
  .nav-link3,
  .nav-link4 {
    height: 40px;
  }
  .nav-link1,
  .nav-link3 {
    height: auto;
  }
  .contact {
    width: 25%;
  }
  .mobile-contact {
    width: 100px;
    padding: 0 7px;
    background: none;
    height: 40px;
    align-items: flex-start;
    justify-content: flex-start;
    margin-left: 0px;
  }
  .link-text6 {
    font-weight: 400;
    color: #242424;
  }
  .nav-link1 ul,
  .nav-link3 ul {
    height: 150px;
    margin-left: -15px;
    padding-top: 0px;
    background-color: transparent;
  }
  .nav-link1 ul li,
  .nav-link3 ul li {
    color: #f8f8f8;
    background-color: transparent;
    height: 30px;
    margin-bottom: 10px;
    padding-top: 0px;
    font-size: 16px;
  }
  #newcolor1 {
    color: #777777;
  }
  #newcolor2 {
    color: #ffffff;
  }
  /*End of Nav */
  .content {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    top: 60px;
    gap: 20px;
  }
  .main-content {
    width: 440px;
    font-size: 37px;
    line-height: 50px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    height: auto;
  }
  .content-text {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    gap: 30px;
  }
  .sub-content {
    width: 85%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
  .btn-content {
    width: 88%;
  }
  .content-btn,
  .content-btn-1 {
    width: 54%;
    height: 50px;
  }
  .content-link {
    font-size: 14px;
  }
  .rotate {
    left: 50%;
    right: auto;
    top: 450px;
  }
  .image {
    left: 30%;
    top: 490px;
    width: 370px;
    height: 310px;
  }
}

@media (min-width: 810px) and (max-width: 839px) {
  .image {
    left: 31%;
  }
}

@media (min-width: 840px) and (max-width: 991px) {
  .image {
    left: 32%;
  }
}

@media (min-width: 900px) and (max-width: 929px) {
  .image {
    left: 33%;
  }
}

@media (min-width: 930px) and (max-width: 991px) {
  .image {
    left: 33.5%;
  }
}

@media (min-width: 992px) and (max-width: 1140px) {
  .container {
    height: 570px;
  }
  .mask {
    width: 133px;
  }
  .contact {
    height: 40px;
  }
  .link-text,
  .link-text1,
  .link-text2,
  .link-text3,
  .link-text4,
  .link-text5,
  .link-text6,
  .nav-link3 ul li {
    font-size: 14px;
  }
  .link-text:hover,
  .link-text1:hover,
  .link-text2:hover,
  .link-text3:hover,
  .link-text4:hover,
  .link-text5:hover,
  .nav-link3:hover span {
    font-size: 16px;
  }
  .contain {
    gap: 5.7%;
  }
  .content {
    height: 370px;
    margin-left: 9%;
  }
  .main-content {
    width: 400px;
    height: 160px;
    font-size: 36px;
    line-height: 50px;
  }
  .content-btn,
  .content-btn-1 {
    width: 74%;
    height: 50px;
    margin-top: -10px;
  }
  .content-link {
    font-size: 14px;
  }
  .rotate {
    top: 110px;
    left: 69.5%;
  }
  .rotate span {
    font-size: 30px;
  }
  .image {
    width: 370px;
    height: 310px;
    top: 150px;
  }
}

@media (min-width: 1080px) and (max-width: 1140px) {
  .rotate {
    left: 66.5%;
    top: 110px;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1399px) {
  .image {
    width: 440px;
    height: 410px;
  }
  #state {
    width: 12.5%;
  }
}

@media screen and (min-width: 1350px) and (max-width: 1399px) {
  .image {
    left: 54.5%;
  }
}

@media screen and (min-width: 1400px) {
  .mask {
    margin-right: 250px;
    margin-left: 120px;
  }
  .content {
    top: 120px;
  }
  .content-link {
    font-size: 18px;
  }
  .link-text,
  .link-text1,
  .link-text2,
  .link-text3,
  .link-text4,
  .link-text5,
  .link-text6 {
    font-size: 18px;
    line-height: 25px;
  }
  .main-content {
    font-size: 54px;
    width: 500px;
    line-height: 62px;
  }
  .sub-content {
    font-size: 18px;
    width: 470px;
  }
  .image {
    left: 55.5%;
    width: 450px;
    height: 430px;
  }
}

@media (min-width: 1500px) {
  .image {
    left: 57%;
  }
}
