@font-face {
  font-family: "Recoleta";
  src: url(Fonts/Recoleta-Regular.ttf);
}

@font-face {
  font-family: "Proxima Nova";
  src: url(Fonts/ProximaNova-Regular.ttf);
}

/*Services Styling*/

.service {
  position: relative;
  width: 88%;
  height: auto;
  margin-top: 50px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 60px;
  border: 2px solid #efefef;
}

.service-header {
  margin-top: -15px;
  position: relative;
  height: 180px;
  margin-bottom: 10px;
}

.servicetxt {
  position: relative;
  height: 20px;
  left: 80px;
  top: 40px;
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #777777;
}

.maintxt {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 10px;
  position: relative;
  width: 42%;
  height: 128px;
  left: 80px;
  top: 50px;
}

.subtext-1 {
  width: 80%;
  height: 54px;
  font-family: "Recoleta";
  font-style: normal;
  font-weight: 600;
  font-size: 35px;
  line-height: 54px;
  color: #2f2f2f;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.subtext-2 {
  width: 88%;
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #777777;
  flex: none;
  order: 1;
  flex-grow: 0;
}

/*Service Card Styling*/

.card-grid {
  display: flex;
  flex-direction: row;
  gap: 30px;
  margin-left: 80px;
  width: 97%;
}

.card-grid-1 {
  gap: 20px;
  margin-left: 80px;
  width: 90%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-top: 30px;
}

.card-grid a,
.card-grid-1 a {
  text-decoration: none;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 430px;
  padding-bottom: 160px;
  margin-top: 30px;
}

.card:hover {
  border: 3px solid #777777;
}

.card-icon {
  position: relative;
  width: 35%;
  height: 90px;
  left: 24px;
  top: 15px;
}

.card-txt {
  position: relative;
  height: auto;
  left: 24px;
  top: 50px;
  font-family: "Recoleta";
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 40px;
  color: #242424;
}

#big {
  width: 280px;
}

#spec {
  width: 200px;
}

.card-content {
  position: relative;
  width: 90%;
  left: 24px;
  top: 70px;
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  color: #777777;
}

.logo1 {
  height: 120px;
  width: 120px;
  background: url("../images/logo1.png");
}

.logo2 {
  height: 90px;
  width: 90px;
  background: url("../images/logo2.png");
  margin-top: 30px;
}

.logo3 {
  height: 120px;
  width: 120px;
  background: url("../images/logo3.png");
}

.logo4 {
  height: 90px;
  width: 90px;
  margin-top: 20px;
  background: url("../images/logo4.png");
}

/* Our Services Styling */

.main {
  width: 82%;
  margin-left: auto;
  margin-right: auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-bottom: 30px;
}

.text-main {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.flex {
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 50px;
  margin-top: 70px;
}

#margin {
  margin-top: 40px;
  margin-bottom: 30px;
}

#margins {
  margin-top: -30px;
}

.serve-text {
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #777777;
}

.serve-main {
  font-family: "Recoleta";
  font-style: normal;
  font-weight: 700;
  font-size: 38px;
  color: #214aa0;
}

.serve-content {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.serve-content p {
  width: 520px;
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #242424;
}

#sizes p {
  width: 85%;
}

#color {
  color: #214aa0;
  font-weight: 600;
}

#disc {
  width: 33%;
  margin-top: 5px;
}

#disc1 {
  width: 33%;
}

.techlist {
  order: 1 !important;
}

.order-2{
  order: 2;
}

.techlist li {
  font-size: 16px !important;
  width: 85%;
}

/*Web Development Styling*/

.web {
  width: 499px;
  height: 400px;
  background: url("../images/web.png");
  background-repeat: no-repeat;
  background-size: contain;
  margin-top: 70px;
  margin-left: 20px;
}

#min {
  height: 210px;
}

#red {
  height: 80px;
  font-size: 42px;
  line-height: 44px;
  width: 450px;
}

/* Tech Resource Styling */

.tech {
  position: relative;
  width: 78%;
  margin-top: 110px;
  margin-left: 30px;
}

.tech-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.tech-img1 {
  width: 98%;
  height: 170px;
  margin-bottom: 14px;
  border-top-left-radius: 80px;
  background: url("../images/design/img1.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.tech-img2 {
  width: 98%;
  height: 170px;
  margin-bottom: 14px;
  border-bottom-left-radius: 80px;
  background: url("../images/design/img2.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.tech-img3 {
  width: 98%;
  height: 170px;
  margin-bottom: 14px;
  border-top-right-radius: 80px;
  background: url("../images/design/img3.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.tech-img4 {
  width: 98%;
  height: 170px;
  margin-bottom: 14px;
  border-bottom-right-radius: 80px;
  background: url("../images/design/img4.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.des-1 {
  position: absolute;
  width: 33%;
  height: 70px;
  top: 65px;
  left: -112px;
  background: url("../images/design/des1.png");
  background-repeat: no-repeat;
  background-size: contain;
}

.des-2 {
  position: absolute;
  width: 40%;
  height: 70px;
  top: 30px;
  left: 97%;
  background: url("../images/design/des2.png");
  background-repeat: no-repeat;
  background-size: contain;
}

.des-3 {
  position: absolute;
  width: 33%;
  height: 70px;
  top: 350px;
  left: -45px;
  background: url("../images/design/des3.png");
  background-repeat: no-repeat;
  background-size: contain;
}

.des-4 {
  position: absolute;
  width: 33%;
  height: 70px;
  top: 313px;
  left: 93.7%;
  background: url("../images/design/des4.png");
  background-repeat: no-repeat;
  background-size: contain;
}

/* Capacity Sizing */

.capacity {
  width: 90%;
  height: 450px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 90px;
  display: grid;
  grid-template-rows: repeat(2, 1fr);
}

.capacity-1 {
  width: 100%;
  background: url("../images/design/cap1.png");
  background-repeat: no-repeat;
  background-size: contain;
}

.capacity-2 {
  width: 100%;
  background: url("../images/design/cap2.png");
  background-repeat: no-repeat;
  background-size: contain;
}

#mins {
  height: 200px;
  width: 95%;
}

#mins p {
  width: 450px;
  line-height: 24px;
}

#disc2 {
  width: 35%;
}

/* Training Styling */

.training {
  height: 550px;
  margin-top: 70px;
  position: relative;
}

.training-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.training-text {
  width: 100%;
  height: 550px;
  background-color: #e9f6f9;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.position {
  margin-top: 35px;
  margin-left: 19%;
}

.train-main {
  width: 68%;
  font-family: "Recoleta";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 35px;
  color: #242424;
}

.train-card {
  width: 57%;
  height: 350px;
  margin-left: 19%;
  border: 2px solid #242424;
  border-radius: 32px;
  background-color: #ffffff;
  z-index: 100;
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.train-head {
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 34px;
  color: #242424;
  margin-top: 40px;
  margin-left: 5%;
  animation: 1s ease;
}

.train-content {
  width: 90%;
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #777777;
  margin-left: 5%;
  animation: 1s ease;
}

.train-card-1 {
  width: 28%;
  height: 350px;
  top: 140px;
  left: 11.2%;
  border: 2px solid #242424;
  border-radius: 32px;
  background-color: #fff6de;
  position: absolute;
}

#tech-1 {
  width: 100%;
  height: 550px;
  background: url("../images/design/train1.png");
  background-repeat: no-repeat;
  background-size: cover;
  animation: 1s ease;
}

#tech-2 {
  width: 100%;
  height: 550px;
  background: url("../images/design/train2.png");
  background-repeat: no-repeat;
  background-size: cover;
  animation: 1s ease;
}

#tech-3 {
  width: 100%;
  height: 550px;
  background: url("../images/design/train3.png");
  background-repeat: no-repeat;
  background-size: cover;
  animation: 1s ease;
}

#tech-4 {
  width: 100%;
  height: 550px;
  background: url("../images/design/train4.png");
  background-repeat: no-repeat;
  background-size: cover;
  animation: 1s ease;
}

.arrow-left {
  width: 45px;
  height: 45px;
  position: absolute;
  background: url("../images/design/left.png");
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
  top: 220px;
  left: 35px;
}

.arrow-right {
  width: 45px;
  height: 45px;
  position: absolute;
  background: url("../images/design/right.png");
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
  top: 220px;
  right: 35px;
}

.arrow-left:hover,
.arrow-right:hover {
  width: 50px;
  height: 50px;
  opacity: 0.7;
  transition: 0.5 ease-in;
}

/* Proj Outsourcing Styling */

#max {
  gap: 10px;
}

#max p {
  width: 100%;
}

#marginss {
  margin-top: -10px;
}

#studioo{
  margin-top: 40px;
}

.studiolist li{
  width: 100%;
}

.proj {
  width: 100%;
  height: 460px;
  background: url("../images/proj.png");
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 90px;
  margin-left: 20px;
}

.proj-icons {
  width: 85%;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 85px;
  margin-top: 90px;
}

.proj-1 {
  width: 120px;
  height: 120px;
  background: url("../images/design/7up.png");
}

.proj-2 {
  width: 120px;
  height: 120px;
  background: url("../images/design/firstbank.png");
}

.proj-3 {
  width: 120px;
  height: 120px;
  background: url("../images/design/fidelity.jpg");
}

.proj-4 {
  width: 120px;
  height: 120px;
  background: url("../images/design/fcmb.png");
}

.proj-5 {
  width: 120px;
  height: 120px;
  background: url("../images/design/polaris.png");
}

/* Media Queries */

@media screen and (max-width: 767px) {
  .card:hover {
    border: none;
  }
  .tech-img1 {
    border-top-left-radius: 30px;
  }
  .tech-img2 {
    border-bottom-left-radius: 30px;
  }
  .tech-img3 {
    border-top-right-radius: 30px;
  }
  .tech-img4 {
    border-bottom-right-radius: 30px;
  }
}

@media screen and (max-width: 480px) {
  .service {
    height: auto;
  }
  .maintxt {
    width: 85%;
    height: 120px;
    left: 10px;
    top: 40px;
    gap: 0px;
  }
  .subtext-1 {
    width: 90%;
    height: 40px;
    font-size: 24px;
    line-height: 32px;
  }
  .subtext-2 {
    width: 250px;
    font-size: 12px;
    line-height: 16px;
  }
  .service-header {
    height: 100px;
  }
  .card-grid, .card-grid-1 {
    margin-left: 10px;
    margin-top: -10px;
    gap: 5px;
  }
  .card-grid-1{
    margin-top: 45px;
    grid-template-columns: repeat(1,1fr);
    gap: 0px;
  }
  .card {
    height: 220px;
  }
  .card-icon {
    height: 20px;
    left: 10px;
  }
  .logo1,
  .logo3 {
    height: 40px;
    width: 40px;
    background-size: contain;
    background-repeat: no-repeat;
  }
  .logo1 {
    margin-top: -10px;
  }
  .logo2,
  .logo4 {
    height: 30px;
    width: 30px;
    margin-top: 10px;
    background-size: contain;
    background-repeat: no-repeat;
  }
  .card-txt {
    width: 49%;
    height: 50px;
    left: 15px;
    top: 45px;
    font-size: 18px;
    line-height: 18px;
  }
  .card-content {
    width: 87%;
    left: 15px;
    top: 60px;
    font-size: 14px;
    line-height: 16px;
  }
 
  #big {
    width: 98%;
    left: 5px;
  }
  /* Services Sub Pages */
  .flex {
    margin-top: 50px;
  }
  .main {
    width: 100%;
    grid-template-columns: repeat(1, 1fr);
    margin-bottom: -160px;
  }
  .text-main {
    gap: 20px;
    margin-left: 5%;
  }
  #disc,
  #disc1 {
    width: 45%;
    height: 40px;
  }
  .serve-content {
    gap: 10px;
  }
  .proj-icons {
    gap: 10px;
    margin-top: 40px;
    width: 96%;
  }
  .proj-1,
  .proj-2,
  .proj-3,
  .proj-4,
  .proj-5 {
    width: 80px;
    height: 80px;
    background-size: contain;
    background-repeat: no-repeat;
  }
  #sizes {
    margin-top: -10px;
  }
  .des-1 {
    left: -30.5%;
  }
  .des-3 {
    top: 213px;
    left: -25px;
  }
  .des-4 {
    top: 185px;
    left: 96%;
  }
  .tech {
    margin-top: 50px;
    width: 55%;
    margin-left: 19%;
    margin-bottom: 20px;
  }
  .tech-img1,
  .tech-img2,
  .tech-img3,
  .tech-img4 {
    height: 100px;
  }
  #margins {
    margin-top: -10px;
    margin-bottom: -10px;
  }
  #red {
    font-size: 28px;
    height: 56px;
    line-height: 32px;
    width: 300px;
  }
  .web {
    margin-top: 40px;
    width: 90%;
    background-size: contain;
    margin-left: 5%;
  }
  .serve-content p,
  #sizes p {
    width: 95%;
    font-size: 14px;
  }
  #min {
    height: 270px;
  }
  .serve-main {
    font-size: 28px;
    height: 28px;
    line-height: 32px;
  }
  .proj {
    width: 90%;
    background-size: contain;
    margin-left: 5%;
    margin-top: 60px;
  }
  #max {
    height: auto;
  }
  #max p {
    width: 95%;
    font-size: 14px;
  }
  /* Capacity */
  .capacity {
    height: 430px;
    margin-bottom: 130px;
  }
  .capacity-1,
  .capacity-2 {
    background-size: cover;
  }
  .train-main {
    font-size: 24px;
    width: 260px;
    line-height: 30px;
  }
  .training-grid {
    grid-template-columns: repeat(1, 1fr);
  }
  #tech-1,
  #tech-2,
  #tech-3,
  #tech-4 {
    display: none;
  }
  .arrow-left,
  .arrow-right {
    width: 35px;
    height: 35px;
  }
  .arrow-left {
    left: 15px;
  }
  .arrow-right {
    right: 15px;
  }
  .train-head {
    font-size: 20px;
    margin-top: 25px;
  }
  .train-card {
    width: 270px;
    margin-left: 5%;
    gap: 10px;
    height: 370px;
  }
  .train-card-1 {
    top: 135px;
    left: 10%;
    width: 270px;
    height: 370px;
  }
  #mins {
    height: auto;
    width: 98%;
    margin-top: -20px;
    margin-bottom: -20px;
  }
  #mins p {
    width: 95%;
    font-size: 14px;
    line-height: 18px;
  }
  #disc2 {
    width: 45%;
  }
  .position {
    margin-top: 30px;
    margin-left: 7%;
  }
}

@media screen and (min-width: 360px) and (max-width: 480px) {
  .train-card {
    margin-left: 11%;
  }
  .train-card-1 {
    left: 16%;
  }
}

@media screen and (min-width: 380px) and (max-width: 480px) {
  .train-card {
    margin-left: 13%;
  }
  .train-card-1 {
    left: 18%;
  }
}

@media screen and (min-width: 390px) and (max-width: 449px) {
  .main {
    margin-bottom: -120px;
  }
  #min {
    height: 240px;
  }
}

@media screen and (min-width: 450px) and (max-width: 480px) {
  .main {
    margin-bottom: -80px;
  }
  #min {
    height: 210px;
  }
  .train-card {
    margin-left: 16%;
  }
  .train-card-1 {
    left: 21%;
  }
  .capacity {
    margin-bottom: 90px;
  }
}

@media screen and (min-width: 481px) and (max-width: 767px) {
  .maintxt {
    width: 70%;
    height: 100px;
    left: 20px;
    gap: 5px;
  }
  .subtext-1 {
    width: 90%;
    height: 35px;
    font-size: 24px;
    line-height: 32px;
  }
  .subtext-2 {
    width: 350px;
    font-size: 14px;
    line-height: 16px;
  }
  .service-header {
    height: 130px;
  }
  .card-grid {
    margin-left: 20px;
    margin-top: -20px;
    gap: 10px;
  }
  .card-grid-1{
    margin-left: 20px;
    grid-template-columns: repeat(1,1fr);
    gap: 0px;
  }
  .card {
    height: 230px;
  }
  .card-icon {
    height: 40px;
    left: 15px;
  }
  .logo1,
  .logo3 {
    height: 60px;
    width: 60px;
    background-size: contain;
    background-repeat: no-repeat;
  }
  .logo1 {
    margin-top: -10px;
  }
  .logo2,
  .logo4 {
    height: 45px;
    width: 45px;
    margin-top: 10px;
    background-size: contain;
    background-repeat: no-repeat;
  }
  .card-txt {
    width: 49%;
    height: 50px;
    left: 20px;
    top: 45px;
    font-size: 20px;
    line-height: 20px;
  }
  .card-content {
    width: 87%;
    left: 20px;
    top: 60px;
    font-size: 14px;
    line-height: 16px;
  }
  #big {
    width: 140px;
  }
  /* Services Sub Pages */
  #disc {
    margin-top: 0.2%;
  }
  .main {
    width: 89%;
    grid-template-columns: repeat(1, 1fr);
  }
  .text-main {
    gap: 15px;
  }
  .serve-content {
    gap: 10px;
  }
  .proj-icons {
    gap: 20px;
    margin-top: 60px;
    width: 92%;
  }
  .proj-1,
  .proj-2,
  .proj-3,
  .proj-4,
  .proj-5 {
    width: 100px;
    height: 100px;
    background-size: contain;
    background-repeat: no-repeat;
  }
  /* Tech Page */
  .des-1 {
    left: -30.5%;
  }
  .des-3 {
    top: 290px;
    left: -25px;
  }
  .des-4 {
    top: 260px;
    left: 91%;
  }
  .tech {
    margin-top: 50px;
    width: 60%;
    margin-left: 17%;
    margin-bottom: 20px;
  }
  .tech-img1,
  .tech-img2,
  .tech-img3,
  .tech-img4 {
    height: 140px;
  }
  /* Web Page */
  #red {
    font-size: 32px;
    height: 75px;
    line-height: 35px;
  }
  .web {
    margin-top: 40px;
    width: 95%;
    background-size: cover;
    margin-left: 0px;
  }
  .serve-content,
  #sizes p,
  #mins p {
    width: 95%;
    font-size: 16px;
  }
  .serve-main {
    font-size: 32px;
    line-height: 35px;
  }
  #max {
    height: auto;
  }
  #max p {
    width: 95%;
    font-size: 16px;
  }
  /* Proj Page */
  .proj {
    width: 97%;
    margin-left: 0px;
    background-size: cover;
    margin-top: 40px;
  }
  /* Capacity Page */
  .capacity {
    height: 430px;
  }
  .capacity-1,
  .capacity-2 {
    background-size: cover;
  }
  .train-main {
    font-size: 26px;
  }
  .training-grid {
    grid-template-columns: repeat(1, 1fr);
  }
  #tech-1,
  #tech-2,
  #tech-3,
  #tech-4 {
    display: none;
  }
  .arrow-left {
    left: 10px;
  }
  .arrow-right {
    right: 10px;
  }
  .train-head {
    font-size: 20px;
    margin-top: 25px;
  }
  .train-card {
    width: 320px;
    margin-left: 14%;
    gap: 10px;
    height: 360px;
  }
  .train-card-1 {
    top: 140px;
    left: 20%;
    width: 310px;
    height: 360px;
  }
  #mins {
    height: auto;
    width: 98%;
  }
}

@media screen and (min-width: 540px) and (max-width: 767px) {
  .train-card {
    margin-left: 20%;
  }
  .train-card-1 {
    left: 25%;
  }
}

@media screen and (min-width: 650px) and (max-width: 767px) {
  .train-card {
    margin-left: 22%;
  }
  .train-card-1 {
    left: 27%;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .maintxt {
    width: 50%;
    left: 50px;
  }
  .subtext-1 {
    width: 90%;
    height: 40px;
    font-size: 24px;
    line-height: 32px;
  }
  .subtext-2 {
    width: 98%;
    font-size: 14px;
    line-height: 18px;
  }
  .service-header {
    height: 150px;
  }
  .card-grid {
    margin-left: 50px;
  }
  .card-grid-1{
    margin-left: 50px;
  }
  .card {
    height: 320px;
  }
  .card-icon {
    height: 70px;
    left: 20px;
  }
  .logo1,
  .logo3 {
    height: 90px;
    width: 90px;
    background-size: contain;
    background-repeat: no-repeat;
  }
  .logo1 {
    margin-top: -10px;
  }
  .logo2,
  .logo4 {
    height: 70px;
    width: 70px;
    margin-top: 10px;
    background-size: contain;
    background-repeat: no-repeat;
  }
  .card-txt {
    width: 49%;
    height: 50px;
    left: 24px;
    top: 40px;
    font-size: 24px;
    line-height: 32px;
  }
  .card-content {
    width: 87%;
    left: 20px;
    top: 70px;
    font-size: 14px;
    line-height: 20px;
  }
  #big {
    width: 78%;
  }
  /* Services Sub Pages */
  #disc {
    margin-top: 40px;
    width: 35%;
  }
  .main {
    width: 89%;
  }
  .text-main {
    gap: 15px;
  }
  .serve-content {
    gap: 10px;
  }
  .proj-icons {
    gap: 40px;
    margin-top: 60px;
  }
  .proj-1,
  .proj-2,
  .proj-3,
  .proj-4,
  .proj-5 {
    width: 100px;
    height: 100px;
    background-size: contain;
    background-repeat: no-repeat;
  }
  /* Tech Page */
  .tech {
    margin-top: 100px;
    width: 68%;
    margin-left: 60px;
  }
  .tech-img1,
  .tech-img2,
  .tech-img3,
  .tech-img4 {
    height: 130px;
  }
  .des-1 {
    left: -30.5%;
  }
  .des-3 {
    top: 270px;
    left: -25px;
  }
  .des-4 {
    top: 235px;
    left: 91%;
  }
  #sizes {
    gap: 5px;
    margin-top: -15px;
  }
  #sizes p {
    font-size: 16px;
    width: 95%;
    line-height: 18px;
  }
  /*Web Page */
  #red {
    font-size: 32px;
    height: 75px;
    line-height: 35px;
  }
  .serve-main {
    font-size: 32px;
    height: 35px;
    line-height: 35px;
  }
  .web {
    margin-top: 70px;
    margin-left: -100px;
    width: 350px;
    background-size: cover;
  }
  .serve-content p {
    width: 350px;
    font-size: 16px;
  }
  /* Proj Page */
  .proj {
    width: 350px;
    background-size: cover;
    margin-left: -10px;
    margin-top: 75px;
  }
  #max {
    height: 235px;
  }
  #max p {
    font-size: 16px;
    line-height: 18px;
    width: 330px;
  }
  /* Capacity Page */
  #disc2 {
    margin-top: -10px;
  }
  #mins {
    margin-top: -20px;
  }
  #mins p {
    width: 360px;
    font-size: 16px;
    line-height: 20px;
  }
  .capacity {
    height: 380px;
    width: 92%;
  }
  .capacity-1,
  .capacity-2 {
    background-size: cover;
  }
  .position {
    margin-left: 15%;
  }
  .train-main {
    font-size: 24px;
    line-height: 28px;
    width: 240px;
  }
  .train-card {
    width: 70%;
    margin-left: 15%;
    gap: 10px;
    height: 360px;
  }
  .train-card-1 {
    top: 140px;
    left: 10.2%;
    width: 34%;
    height: 360px;
  }
  .arrow-left {
    left: 10px;
  }
  .arrow-right {
    right: 10px;
  }
  .train-head {
    font-size: 20px;
    margin-top: 20px;
  }
  .train-content {
    line-height: 20px;
  }
}

@media screen and (min-width: 880px) and (max-width: 991px) {
  .main {
    width: 84%;
  }
  .web {
    margin-left: -60px;
    width: 400px;
  }
  .proj {
    width: 400px;
  }
  .proj-icons {
    gap: 50px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1140px) {
  .subtext-1 {
    font-size: 30px;
  }
  .subtext-2 {
    font-size: 14px;
  }
  .card-txt {
    width: 180px;
    height: 50px;
    line-height: 32px;
    font-size: 22px;
  }
  #spec {
    width: 160px;
  }
  .card-content {
    font-size: 14px;
  }
  .flex {
    margin-top: 50px;
  }
  /* Services Sub Pages */
  #disc {
    margin-top: 40px;
  }
  .main {
    margin-left: 6.8%;
  }
  .text-main {
    gap: 15px;
  }
  .serve-content {
    gap: 10px;
  }
  .resize {
    height: 430px;
  }
  .proj {
    margin-top: 50px;
    background-size: cover;
  }
  .proj-icons {
    gap: 70px;
    margin-top: 50px;
  }
  .proj-1,
  .proj-2,
  .proj-3,
  .proj-4,
  .proj-5 {
    width: 100px;
    height: 100px;
    background-size: contain;
    background-repeat: no-repeat;
  }
  #margins {
    margin-top: -10px;
  }
  .des-1 {
    left: -31%;
  }
  .des-2 {
    left: 97%;
  }
  .des-4 {
    left: 93.8%;
  }
  .tech {
    margin-top: 95px;
    width: 83%;
  }
  #red {
    font-size: 36px;
    height: 75px;
    line-height: 39px;
  }
  .web {
    margin-top: 55px;
    margin-left: 0px;
    width: 410px;
    background-size: cover;
  }
  .serve-content p,
  #max p {
    width: 410px;
    font-size: 16px;
  }
  #max {
    height: 220px;
  }
  #marginss {
    margin-top: 10px;
  }
  #disc2 {
    margin-top: 20px;
  }
  .capacity {
    height: 380px;
    width: 99%;
    margin-left: 20px;
  }
  .capacity-1,
  .capacity-2 {
    background-size: cover;
  }
  #mins {
    height: 210px;
  }
  .train-main {
    font-size: 26px;
  }
  .train-head {
    font-size: 20px;
  }
  .train-card-1 {
    top: 150px;
  }
  .train-content {
    line-height: 18px;
    width: 93%;
  }
}

@media screen and (min-width: 1060px) and (max-width: 1140px) {
  .web {
    width: 100%;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1399px) {
  .des-2 {
    left: 97.3%;
  }
  .des-4 {
    left: 94%;
  }
  .proj-icons {
    gap: 8%;
  }
  .tech {
    margin-top: 110px;
  }
}

@media screen and (min-width: 1400px) {
  /* Home Page Services */
  .service-header {
    height: 200px;
  }
  .subtext-1 {
    font-size: 40px;
    line-height: 45px;
  }
  .card-txt {
    width: 200px;
    font-size: 30px;
  }
  #big {
    width: 250px;
  }
  #spec {
    width: 170px;
  }
  .des-2 {
    left: 97.3%;
  }
  .des-4 {
    left: 94%;
  }
  .serve-content {
    gap: 10px;
  }
  .serve-content p {
    font-size: 17px;
  }
  .proj-icons {
    gap: 100px;
  }
  #mins {
    width: 90%;
  }
  .train-head {
    font-size: 28px;
  }
  .train-content {
    font-size: 15px;
  }
  .subtext-2,
  .card-content {
    font-size: 20px;
  }
  .card-content {
    width: 92%;
  }
  #tech-1,
  #tech-2,
  #tech-3,
  #tech-4,
  .training-text,
  .training-grid {
    height: 600px;
  }
}
