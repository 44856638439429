@font-face {
    font-family: "Recoleta";
    src: url(Fonts/Recoleta-Regular.ttf);
}

@font-face {
    font-family: "Proxima Nova";
    src: url(Fonts/ProximaNova-Regular.ttf);
}


/* Testimonial Styling */

.testimonial {
    position: relative;
    width: 100%;
    height: 580px;
    left: 0px;
    top: 10px;
    background: #FFFFFF;
}

.test-head {
    position: relative;
    margin-left: 2%;
    height: 193px;
}

.test-cards {
    position: relative;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    top: 60px;
    padding-left: 8%;
    padding-right: 8%;
    overflow-x: scroll;
}

.test-cards::-webkit-scrollbar {
    display: none;
}

.test-text-1 {
    margin-top: 25px;
    width: 370px;
    height: 100px;
    font-family: 'Recoleta';
    font-style: normal;
    font-weight: 600;
    font-size: 35px;
    line-height: 45px;
    color: #2F2F2F;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin-bottom: -15px;
}

.my-card {
    width: 245px;
    height: 260px;
    background: #efefef;
    border: 1px solid #efefef;
    margin-bottom: 30px;
    margin-right: 15px;
}

.my-card-text {
    position: relative;
    width: 220px;
    height: 125px;
    left: 15px;
    top: 60px;
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #777777;
}

.test-tag {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 14px;
    position: relative;
    width: 163px;
    height: 50px;
    left: 20px;
    top: 30px;
}

.test-name {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 4px;
    width: 93px;
    height: 43px;
    flex: none;
    order: 1;
    flex-grow: 0;
}

.tag-name {
    width: 137px;
    height: 24px;
    font-family: 'Recoleta';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #242424;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.tag-job {
    width: 147px;
    height: 28px;
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 15px;
    color: #242424;
    flex: none;
    order: 1;
    flex-grow: 0;
}

.ojo,
.okoli,
.tayo,
.usman,
.michael {
    width: 54px;
    height: 54px;
    border-radius: 50%;
    flex: none;
    order: 0;
    flex-grow: 0;
    background-size: contain;
    background-repeat: no-repeat;
}

.ojo {
    background: url("../images/ojo.png");
}

.okoli {
    background: url("../images/okoli.png");
}

.tayo {
    background: url("../images/tayo.png");
}

.usman {
    background: url("../images/usman.png");
}

.michael {
    background: url("../images/michael.png");
}


/* Media Queries */

@media (max-width: 480px) {
    .testimonial {
        height: 430px;
    }
    .my-card {
        width: 200px;
        height: 240px;
    }
    .my-card-text {
        width: 180px;
        font-size: 12px;
        top: 40px;
        line-height: 17px;
        left: 10px;
    }
    .test-tag {
        gap: 5px;
        left: 10px;
        top: 20px;
    }
    .test-head {
        margin-left: 3%;
        height: 150px;
    }
    .test-text-1 {
        margin-top: 5px;
        font-size: 18px;
        line-height: 30px;
        width: 220px;
        height: 75px;
    }
    .test-cards {
        top: 0px;
        padding-left: 20px;
        padding-right: 20px;
    }
    .ojo,
    .okoli,
    .tayo,
    .usman,
    .michael {
        width: 40px;
        height: 40px;
        background-size: contain;
    }
    .tag-name {
        font-size: 14px;
        line-height: 20px;
        height: 20px;
    }
    .tag-job {
        font-size: 11px;
        width: 130px;
    }
}

@media (min-width: 481px) and (max-width: 767px) {
    .testimonial {
        height: 500px;
    }
    .my-card {
        width: 200px;
        height: 230px;
    }
    .my-card-text {
        width: 170px;
        font-size: 10px;
        top: 50px;
        line-height: 17px;
    }
    .test-tag {
        gap: 5px;
    }
    .test-head {
        margin-left: 1.5%;
    }
    .test-text-1 {
        margin-top: 5px;
        font-size: 24px;
        line-height: 30px;
        width: 83%;
        height: 75px;
    }
    .test-cards {
        top: 0px;
        padding-left: 25px;
        padding-right: 25px;
    }
    .ojo,
    .okoli,
    .tayo,
    .usman,
    .michael {
        width: 40px;
        height: 40px;
        background-size: contain;
    }
    .tag-name {
        font-size: 14px;
        line-height: 20px;
        height: 20px;
    }
    .tag-job {
        font-size: 11px;
        width: 130px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .my-card {
        width: 240px;
        height: 260px;
    }
    .my-card-text {
        width: 210px;
    }
}

@media screen and (min-width: 992px) and (max-width: 1140px) {
    .parttxt {
        left: 6.3%;
        font-size: 14px;
    }
    .test-text-1 {
        font-size: 30px;
        line-height: 40px;
        height: 85px;
    }
    .test-cards {
        top: 50px;
        padding-left: 7.4%;
        padding-right: 7.4%;
    }
}

@media screen and (min-width: 1400px) {
    .maintext {
        left: 7.2%;
    }
    .test-text-1 {
        font-size: 40px;
        width: 450px;
    }
    .my-card {
        width: 280px;
        height: 280px;
    }
    .my-card-text {
        font-size: 16px;
        width: 260px;
    }
    .tag-job {
        font-size: 13px;
        width: 170px
    }
}