.footer {
    position: relative;
    width: 100%;
    height: auto;
    padding-bottom: 40px;
    left: 0px;
    background: #214AA0;
    display: flex;
    flex-direction: row;
    overflow-y: hidden;
    overflow-x: hidden;
    gap: 7%;
}


/* First Footer Section */

.conclase {
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin-left: 9.4%;
    width: 306px;
}

.footer-log {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 30px;
}

.conclase-logo {
    width: 133px;
    height: 60px;
    background: url("../images/conclase.png");
    background-size: contain;
    background-repeat: no-repeat;
}

.description {
    color: #f8f8f8;
    font-size: 18px;
    line-height: 24px;
    font-family: "Proxima Nova";
    font-weight: 400;
}

.footer-media {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.footer-header {
    font-family: 'Recoleta';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #FDC329;
}

.social-media {
    display: flex;
    flex-direction: row;
    gap: 20px;
    width: 40%;
}

.media-icon1 {
    width: 20px;
    height: 20px;
    background: url('../images/linkedin.png');
    background-repeat: no-repeat;
    background-size: contain;
}

.media-icon2 {
    width: 24px;
    height: 20px;
    background: url('../images/twitter.png');
    background-repeat: no-repeat;
    background-size: contain;
}

.media-icon3 {
    width: 20px;
    height: 20px;
    background: url('../images/instagram.png');
    background-repeat: no-repeat;
    background-size: contain;
}

.media-icon4 {
    width: 20px;
    height: 20px;
    background: url('../images/facebook.png');
    background-repeat: no-repeat;
    background-size: contain;
}

.conclase-logo:hover,
.media-icon1:hover,
.media-icon2:hover,
.media-icon3:hover,
.media-icon4:hover {
    transform: scale(0.8);
    transition: 0.3s ease;
}


/*End of First Section */


/* Other Half of Footer */

.footer-links {
    display: flex;
    flex-direction: row;
    gap: 10%;
    margin-top: 40px;
}


/* 1st Other Half Section */

.company-links {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 110px;
}

.comp-links {
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.f-link {
    text-decoration: none;
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #F8F8F8;
}

.f-link:hover {
    font-size: 20px;
    transition: 0.3s ease;
    color: #fdc329;
}


/* End of 1st Other Half */


/* 2nd Other Half */

.location {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.main-locate {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 230px;
}

.country {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #FFFFFF;
}

.city,
.locate {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #F8F8F8;
}


/* 2nd Contact Info */

.locate-contact {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 230px;
}

.can-num {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #F8F8F8;
}

.can-mail {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #F8F8F8;
}

.option {
    display: none;
}


/*Media Query */

@media (max-width: 991px) {
    .conclase-logo:hover,
    .media-icon1:hover,
    .media-icon2:hover,
    .media-icon3:hover {
        transform: scale(1);
        transition: 0.3s ease;
    }
    .f-link:hover {
        font-size: 16px;
        color: #ffffff;
    }
}

@media (max-width: 767px) {
    .footer {
        flex-direction: column;
        gap: 0px;
        height: auto;
        padding-bottom: 90px;
    }
    .conclase {
        margin-left: 7%;
        width: 90%;
    }
    .footer-media {
        display: none;
    }
    .footer-links {
        margin-left: 7%;
        gap: 30px;
        flex-direction: column;
    }
    .option {
        display: flex;
    }
    .company-links {
        width: 70%;
        order: 0;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .footer {
        flex-direction: column;
        gap: 20px;
        height: auto;
        padding-bottom: 80px;
    }
    .conclase {
        margin-left: 9%;
        width: 400px;
    }
    .footer-media {
        display: none;
    }
    .footer-links {
        margin-left: 9%;
        gap: 8%;
    }
    .option {
        display: flex;
    }
}

@media (min-width: 860px) and (max-width: 991px) {
    .footer-links {
        margin-left: 9%;
        gap: 13%;
    }
}

@media (min-width: 992px) and (max-width: 1140px) {
    .conclase {
        margin-left: 7%;
    }
    .footer {
        gap: 5%;
    }
    .footer-links {
        gap: 11%;
    }
    .conclase {
        width: 270px;
    }
    .description {
        font-size: 16px;
    }
    .footer-header {
        font-size: 18px;
    }
    .f-link {
        font-size: 16px;
    }
    .f-link:hover {
        font-size: 18px;
    }
    .country {
        font-size: 16px;
    }
    .city,
    .locate {
        font-size: 14px;
    }
    .main-locate,
    .locate-contact {
        width: 190px;
    }
    .can-num,
    .can-mail {
        font-size: 16px;
    }
}

@media (min-width: 1400px) {
    .footer {
        gap: 9%;
    }
    .footer-links {
        gap: 16%;
    }
}