@font-face {
    font-family: "Recoleta";
    src: url(Fonts/Recoleta-Regular.ttf);
}

@font-face {
    font-family: "Proxima Nova";
    src: url(Fonts/ProximaNova-Regular.ttf);
}


/*Partners Styling*/

.partners {
    position: relative;
    width: 88%;
    height: auto;
    padding-bottom: 50px;
    margin-left: auto;
    margin-right: auto;
    top: 50px;
    border: 2px solid #EFEFEF;
    margin-bottom: 40px;
    margin-top: -10px;
}

.parttxt {
    position: relative;
    width: 6%;
    height: 25px;
    left: 80px;
    top: 50px;
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 25px;
    color: #777777;
}

.maintext {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 20px;
    position: relative;
    width: 45%;
    height: auto;
    left: 80px;
    top: 40px;
}

.parttext-1 {
    width: 450px;
    height: auto;
    font-family: 'Recoleta';
    font-style: normal;
    font-weight: 600;
    font-size: 35px;
    line-height: 54px;
    color: #2F2F2F;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.parttext-2 {
    width: 380px;
    height: 50px;
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 25px;
    color: #777777;
    flex: none;
    order: 1;
    flex-grow: 0;
}

.part-logo {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 5%;
    margin-top: 70px;
    margin-left: 7%;
    width: 90%;
    height: 180px;
}

.part-logo1 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 7%;
    margin-top: -75px;
    margin-left: 7%;
    width: 90%;
}

.sage {
    background: url('../images/sage.png');
    background-repeat: no-repeat;
    height: 45px;
    width: 116px;
}

.connectwise {
    background: url('../images/connectwise1.png');
    background-repeat: no-repeat;
    height: 45px;
    width: 289px;
}

.microsoft {
    background: url('../images/microsoft.png');
    background-repeat: no-repeat;
    height: 45px;
    width: 212px;
}

.reddit {
    background: url('../images/reddit.png');
    background-repeat: no-repeat;
    height: 45px;
    width: 132px;
}

.resize {
    height: 480px;
    opacity: 0.9;
}


/* Media Queries */

@media (max-width: 480px) {
    .partners {
        height: 250px;
    }
    .maintext {
        width: 350px;
        left: 10px;
        height: auto;
        gap: 5px;
        top: 30px;
    }
    .parttext-1 {
        width: 250px;
        font-size: 20px;
        line-height: 28px;
        height: 30px;
    }
    .parttext-2 {
        font-size: 12px;
        width: 260px;
        line-height: 16px;
    }
    .part-logo,
    .part-logo1 {
        width: 90%;
        gap: 3.4%;
        height: 135px;
        margin-left: 5%;
    }
    .part-logo {
        margin-top: 40px;
    }
    .part-logo1 {
        margin-top: -95px;
    }
    .sage,
    .connectwise,
    .microsoft,
    .reddit {
        background-size: contain;
    }
    .resize {
        width: 92%;
        height: 230px;
    }
}

@media (min-width: 481px) and (max-width: 767px) {
    .partners {
        height: auto;
        padding-bottom: 30px;
    }
    .maintext {
        width: 350px;
        left: 23px;
        height: auto;
    }
    .parttext-1 {
        width: 280px;
        font-size: 24px;
        line-height: 34px;
        height: 35px;
    }
    .parttext-2 {
        font-size: 14px;
        width: 300px;
        line-height: 20px;
    }
    .part-logo,
    .part-logo1 {
        width: 90%;
        gap: 3.7%;
        height: 135px;
        margin-left: 5%;
    }
    .part-logo {
        margin-top: 65px;
    }
    .sage,
    .connectwise,
    .microsoft,
    .reddit {
        background-size: contain;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .maintext {
        width: 58%;
        left: 55px;
    }
    .parttext-1 {
        width: 91%;
        font-size: 30px;
    }
    .part-logo {
        width: 88%;
        gap: 5%;
    }
    .part-logo1 {
        gap: 8%;
    }
    .sage,
    .connectwise,
    .microsoft,
    .reddit {
        background-size: contain;
    }
    .resize {
        height: 380px;
    }
}

@media screen and (min-width: 992px) and (max-width: 1140px) {
    .maintext {
        left: 60px;
    }
    .part-logo {
        margin-top: 60px;
        width: 88%;
        gap: 5%;
    }
    .part-logo1 {
        width: 88%;
        gap: 8%;
    }
    .sage {
        width: 100px;
        background-size: contain;
    }
    .connectwise {
        width: 273px;
        background-size: contain;
    }
    .microsoft {
        width: 196px;
        background-size: contain;
    }
    .reddit {
        width: 116px;
        background-size: contain;
    }
    .resize {
        height: 410px;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1399px) {
    .part-logo {
        gap: 7%;
    }
    .part-logo1 {
        gap: 8.5%;
    }
}

@media screen and (min-width: 1400px) {
    .part-logo1,
    .part-logo {
        gap: 9%;
    }
    .parttext-2 {
        font-size: 18px;
        width: 400px;
    }
}