@font-face {
    font-family: "Recoleta";
    src: url(Fonts/Recoleta-Regular.ttf);
}

@font-face {
    font-family: "Proxima Nova";
    src: url(Fonts/ProximaNova-Regular.ttf);
}

.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    height: 100vh;
    z-index: 1000;
}

.popup-inner {
    position: relative;
    width: 70%;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    height: 92vh;
    display: flex;
    flex-direction: row;
}


/* PopUp Side 1 */

.pop-1 {
    width: 50%;
    background-color: #fff6de;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pop-img-1 {
    width: 50%;
    height: 160px;
    background: url("../images/icons/mail.png");
    background-repeat: no-repeat;
    background-size: contain;
}


/* PopUp Side 2 */

.pop-2 {
    width: 50%;
    background-color: #ffffff;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.close-btn {
    position: absolute;
    top: 11px;
    right: 18px;
    font-size: 20px;
    cursor: pointer;
}

.pop-card {
    display: flex;
    flex-direction: column;
    width: 85%;
    margin-left: auto;
    margin-right: auto;
    gap: 10px;
}

.pop-head {
    font-family: 'Recoleta';
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 25px;
    color: #242424;
    margin-top: 30px;
}

.pop-text {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 25px;
    color: #777777;
}

.pop-form {
    display: flex;
    flex-direction: column;
}


/*Media Query */

@media (max-width: 480px) {
    .popup-inner {
        width: 90%;
        height: 95vh;
    }
    .pop-1 {
        display: none;
    }
    .pop-2 {
        width: 100%;
    }
}

@media (min-width: 481px) and (max-width: 767px) {
    .popup-inner {
        width: 85%;
        height: 95vh;
    }
    .pop-1 {
        display: none;
    }
    .pop-2 {
        width: 100%;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .popup-inner {
        width: 85%;
    }
}

@media (min-width: 992px) and (max-width: 1140px) {
    .popup-inner {
        width: 80%;
    }
}

@media (min-width: 1200px) and (max-width: 1399px) {
    .pop-head {
        margin-top: 20px;
    }
    .popup-inner {
        height: 95vh;
        margin-top: 10px;
    }
}

@media(min-width: 1400px) {
    .popup-inner {
        height: 95vh;
        margin-top: 10px;
    }
}


/* Load Popup */

.load {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ffffff;
}

.load-img {
    width: 256px;
    height: 117px;
    background: url("../images/conclase4.png");
    background-size: contain;
    background-repeat: no-repeat;
    animation: grow 3s infinite;
}

@keyframes grow {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(1.3);
    }
}