@font-face {
  font-family: "Recoleta";
  src: url(Fonts/Recoleta-Regular.ttf);
}

@font-face {
  font-family: "Proxima Nova";
  src: url(Fonts/ProximaNova-Regular.ttf);
}

/*Product Styling*/

.product {
  position: relative;
  width: 100%;
  height: auto;
  padding-bottom: 40px;
  margin-top: 40px;
  background: #ffffff;
}

.prod-header {
  height: 140px;
  margin-bottom: 20px;
}

.prodtitle {
  position: relative;
  height: 20px;
  left: 9.8%;
  top: 30px;
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #777777;
}

.prodtext {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 10px;
  position: relative;
  width: 38%;
  height: 140px;
  left: 9.8%;
  top: 35px;
}

.prodtext-1 {
  width: 100%;
  height: 40px;
  font-family: "Recoleta";
  font-style: normal;
  font-weight: 600;
  font-size: 33px;
  line-height: 40px;
  position: relative;
  color: #242424;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.prodtext-2 {
  width: 98%;
  height: 50px;
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #242424;
  flex: none;
  order: 1;
  flex-grow: 0;
}

/* Card Styling */

.prod-section{
  display: flex;
  flex-direction: column;
  gap: 25px;
  width: 100%;
}

.prod-cards {
  position: relative;
  width: 80%;
  top: -20px;
  margin-left: auto;
  margin-right: auto;
  display: grid;
  /* flex-direction: row; */
  margin-bottom: 24px;
  grid-template-columns: repeat(2,1fr);
  gap: 20px;
}

.pro-card {
  height: 370px;
  width: 100%;
  position: relative;
  background: #d9d9d9;
}

.card-container {
  position: absolute;
  width: 100%;
  height: 370px;
  opacity: 0;
}

.elements {
  width: 77%;
  height: 370px;
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  gap: 15px;
  justify-content: center;
  align-items: center;
}

.candle {
  background: url("../images/icons/academy.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.retrac {
  background: url("../images/icons/Retrac365.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.jobs {
  background: url("../images/icons/jobs.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.candle:hover .card-container,
.retrac:hover .card-container,
.jobs:hover .card-container {
  transition: 0.5s ease;
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.5);
}

.prods-name {
  font-family: "Proxima Nova";
  text-align: center;
  font-style: normal;
  font-weight: 700;
  font-size: 38px;
  line-height: 39px;
  color: #ffffff;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.prods-name-sub {
  font-family: "Proxima Nova";
  font-size: 16px;
  color: #ffffff;
  font-weight: 400;
  text-align: center;
}

.card-links {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 12px 20px;
  width: 150px;
  height: 40px;
  background: #fdc329;
  border-radius: 4px;
  text-decoration: none;
}

.card-links:hover {
  opacity: 0.8;
}

.card-link-span {
  height: 20px;
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #242424;
  flex: none;
  order: 0;
  flex-grow: 0;
}

/* Product Page Section Styling */

.prod-outlook {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
}

.prod-head {
  width: 45%;
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.prod-maintxt {
  font-family: "Recoleta";
  font-style: normal;
  font-weight: 700;
  font-size: 45px;
  line-height: 63px;
  text-align: center;
  color: #242424;
}

.prod-maintxt span {
  color: #214aa0;
}

.prod-subtxt {
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  color: #777777;
  text-align: center;
}

.prod-body {
  width: 50%;
  display: grid;
  grid-template-rows: repeat(3, 1fr);
}

.prod-body-card {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 40px;
}

.body-img,
.body-img1,
.body-img2,
.body-img3 {
  width: 100%;
  height: 350px;
  background: url("../images/icons/acad.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.body-img3 {
  background: url("../images/icons/jobs1.png");
}

.body-img1 {
  background: url("../images/icons/retrac.png");
}

.body-img2 {
  background: url("../images/icons/Kiyuyo.png");
}

.body-desc {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.body-content {
  width: 95%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.body-head {
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 32px;
  color: #242424;
}

.body-sub {
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #242424;
}

.body-symbol {
  width: 25%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.coming-soon {
  width: 135px;
  height: 45px;
  background: #f2e3bb;
  border-radius: 24px;
  text-align: center;
  padding: 14px 20px;
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  color: #242424;
  cursor: default;
}

.new-edit {
  justify-content: flex-end;
  padding-bottom: 5px;
}

.read-more {
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 20px;
  color: #214aa0;
  opacity: 0.2;
  margin-left: 60px;
  cursor: default;
}

.live {
  opacity: 1;
  color: #777777;
  text-decoration: none;
  cursor: pointer;
}

.live:hover {
  color: #fdc329;
  transform: scale(1.05);
  transition: 0.3s ease;
}

/* Media Query */

@media screen and (max-width: 480px) {
  .product {
    margin-top: 20px;
    padding-bottom: 0px;
  }
  .prod-header {
    height: auto;
    margin-bottom: 0px;
  }
  .prodtext {
    width: 93%;
    top: 10px;
    left: 7%;
    height: 120px;
  }
  .prodtext-1 {
    font-size: 24px;
    line-height: 30px;
    height: auto;
  }
  .prodtext-2 {
    width: 97%;
    font-size: 12px;
    line-height: 16px;
  }
  .prod-cards {
    width: 85%;
    gap: 30px;
    grid-template-columns: repeat(1,1fr);
  }
  .pro-card {
    width: 100%;
    height: 200px;
  }
  .retrac,
  .candle,
  .jobs {
    background-size: cover;
  }
  .card-container,
  .elements {
    height: 200px;
    gap: 10px;
  }
  .elements {
    width: 85%;
  }
  .prods-name {
    font-size: 18px;
    line-height: 20px;
  }
  .prods-name-sub {
    font-size: 10px;
  }
  .card-links {
    width: 90px;
    height: 30px;
  }
  .card-link-span {
    font-size: 10px;
  }
  /*Prods Page */
  .prod-outlook {
    margin-bottom: 10px;
  }
  .prod-head {
    width: 280px;
    gap: 10px;
  }
  .prod-body {
    width: 94%;
    margin-left: auto;
  }
  .prod-maintxt {
    font-size: 24px;
    line-height: 30px;
  }
  .prod-subtxt {
    font-size: 14px;
    line-height: 16px;
  }
  .prod-body-card {
    margin-top: 20px;
    gap: 10px;
  }
  .body-img,
  .body-img1,
  .body-img2,
  .body-img3 {
    height: 200px;
    width: 90%;
    background-repeat: no-repeat;
  }
  .body-head {
    font-size: 18px;
    line-height: 24px;
  }
  .body-sub {
    font-size: 14px;
    line-height: 15px;
  }
  .body-content{
    width: 95%;
  }
  .coming-soon {
    width: 90px;
    height: 40px;
    font-size: 10px;
    padding: 14px 0px;
  }
  .body-content,
  .body-symbol {
    gap: 0px;
  }
  .body-symbol {
    width: 100px;
  }
  .read-more {
    font-size: 12px;
    margin-left: 10px;
  }
}

@media screen and (min-width: 481px) and (max-width: 767px) {
  .product {
    margin-top: 20px;
    padding-bottom: 10px;
  }
  .prod-header {
    height: auto;
  }
  .prodtext {
    width: 68%;
    top: 25px;
    left: 7%;
    height: 100px;
  }
  .prodtext-1 {
    font-size: 24px;
    line-height: 30px;
    height: auto;
  }
  .prodtext-2 {
    width: 370px;
    font-size: 14px;
    line-height: 16px;
  }
  .prod-cards {
    width: 85%;
    gap: 30px;
    grid-template-columns: repeat(1,1fr);
  }
  .pro-card {
    width: 100%;
    height: 200px;
  }
  .retrac,
  .candle,
  .jobs {
    background-size: cover;
  }
  .card-container,
  .elements {
    height: 200px;
    gap: 20px;
  }
  .prods-name {
    font-size: 24px;
    line-height: 24px;
  }
  .prods-name-sub {
    font-size: 14px;
  }
  .card-links {
    width: 120px;
    height: 30px;
  }
  .card-link-span {
    font-size: 14px;
  }
  /*Prods Page */
  .prod-outlook {
    margin-bottom: 10px;
  }
  .prod-head {
    width: 390px;
  }
  .prod-body {
    width: 75%;
    margin-left: auto;
    margin-right: auto;
  }
  .prod-maintxt {
    font-size: 28px;
    line-height: 35px;
  }
  .prod-subtxt {
    font-size: 14px;
  }
  .prod-body-card {
    margin-top: 20px;
  }
  .body-img,
  .body-img1,
  .body-img2,
  .body-img3 {
    height: 250px;
    background-repeat: no-repeat;
  }
  .body-head {
    font-size: 20px;
  }
  .body-sub {
    font-size: 14px;
    line-height: 15px;
  }
  .coming-soon {
    width: 100px;
    height: 40px;
    font-size: 10px;
    padding: 14px 10px;
  }
  .body-content{
    width: 95%;
  }
  .body-content,
  .body-symbol {
    gap: 5px;
  }
  .read-more {
    font-size: 12px;
    margin-left: 30px;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .product {
    margin-top: 20px;
  }
  .prod-cards{
    width: 83%;
  }
  .prodtext {
    width: 68%;
    top: 25px;
    left: 10%;
  }
  .prodtext-2 {
    width: 78%;
  }
  .prods-name {
    font-size: 34px;
  }
  /*Prods Page */
  .prod-head {
    width: 550px;
  }
  .prod-body {
    width: 68%;
  }
  .body-sub {
    font-size: 14px;
  }
  .body-symbol {
    width: 27%;
  }
  .read-more {
    font-size: 15px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1140px) {
  .prodtext {
    width: 45%;
  }
  .prodtext-1 {
    font-size: 30px;
  }
  .prodtext-2,
  .prods-name-sub {
    font-size: 14px;
  }
  .prods-name {
    font-size: 32px;
  }
  /* Prods Page */
  .prod-head {
    width: 55%;
  }
  .body-sub {
    font-size: 14px;
  }
  .body-content {
    width: 73%;
  }
  .body-head {
    font-size: 24px;
  }
  .coming-soon {
    width: 115px;
    padding: 14px 10px;
  }
  .read-more {
    font-size: 15px;
    margin-left: 35px;
  }
}

@media screen and (min-width: 1400px) {
  .body-sub {
    font-size: 16px;
  }
  .body-head {
    font-size: 32px;
  }
  .prodtext-1 {
    font-size: 40px;
  }
  .prodtext-2 {
    font-size: 18px;
  }
  .prods-name {
    font-size: 40px;
  }
  .prods-name-sub {
    font-size: 18px;
  }
  .card-link-span {
    font-size: 16px;
  }
}
