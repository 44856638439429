@font-face {
    font-family: "Recoleta";
    src: url(Fonts/Recoleta-Regular.ttf);
}

@font-face {
    font-family: "Proxima Nova";
    src: url(Fonts/ProximaNova-Regular.ttf);
}


/* Subscribe Style */

.subscribe {
    position: relative;
    width: 100%;
    height: 332px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    background: #FFF6DE;
    gap: 12.5%;
    margin-top: 100px;
    margin-bottom: -10px;
}

.design-1 {
    width: 17%;
    height: 332px;
    display: flex;
    align-items: flex-end;
}

.design-2 {
    width: 17%;
    height: 332px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
}

.design-image {
    width: 100%;
    height: 130px;
    background: url("../images/design-1.png");
    background-repeat: no-repeat;
}

.design-image1 {
    width: 90%;
    height: 137px;
    background: url("../images/design-2.png");
    background-repeat: no-repeat;
}

.subscribe-box {
    width: 42%;
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.subscribe-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    margin-left: auto;
    margin-right: auto;
    width: 95%;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.subscribe-header h4 {
    width: 96%;
    font-family: 'Recoleta';
    font-style: normal;
    font-weight: 600;
    font-size: 34px;
    color: #242424;
    height: 45px;
    flex: none;
    order: 0;
    flex-grow: 0;
    text-align: center;
}

.subscribe-header span {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 25px;
    text-align: center;
    width: 100%;
    color: #242424;
    height: 50px;
    flex: none;
    order: 1;
    flex-grow: 0;
}

.subscribe-form {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 4%;
}

.form,
.form-new {
    width: 100%;
    height: 45px;
    border: 1px solid #214aa0;
    background: #fff6de;
    border-radius: 6px;
    text-align: center;
}

.form-new {
    border: 1px solid #fdc329;
    background: transparent;
}

.subscribe-btn,
.btn-new {
    height: 45px;
    width: 28%;
    border: 2px solid #214AA0;
    background: #214AA0;
    border-radius: 8px;
    color: #ffffff;
    font-size: 18px;
    line-height: 25px;
    cursor: pointer;
}

.btn-new {
    border: 2px solid #fdc329;
    background: #fdc329;
    color: #214aa0;
    font-size: 15px;
}

.subscribe-btn:hover,
.btn-new:hover {
    transform: scale(0.9);
    transition: 0.3s ease;
}


/* Media Queries */

@media (max-width: 480px) {
    .subscribe {
        height: 300px;
        margin-top: 80px;
    }
    .design-1 {
        width: 81px;
        height: 40px;
        position: absolute;
        bottom: -70px;
        left: 0px;
    }
    .design-2 {
        width: 81px;
        height: 37px;
        position: absolute;
        right: 0px;
        top: 0px;
    }
    .design-image,
    .design-image1 {
        background-size: contain;
    }
    .subscribe-header {
        gap: 10px;
    }
    .subscribe-header h4 {
        font-size: 18px;
        height: 25px;
    }
    .subscribe-header span {
        font-size: 12px;
        line-height: 16px;
        width: 260px;
    }
    .subscribe-box {
        margin-left: auto;
        margin-right: auto;
        width: 82%;
        gap: 10px;
    }
    .subscribe-form {
        margin-left: 3%;
        flex-direction: column;
    }
    .subscribe-btn {
        font-size: 14px;
        line-height: 18px;
    }
    .form,
    .subscribe-btn {
        margin-left: 3%;
        width: 85%;
        height: 35px;
        margin-bottom: 3px;
    }
}

@media (min-width: 481px) and (max-width: 767px) {
    .subscribe {
        height: 270px;
    }
    .design-1 {
        width: 101px;
        height: 50px;
        position: absolute;
        bottom: -50px;
        left: 0px;
    }
    .design-2 {
        width: 101px;
        height: 47px;
        position: absolute;
        right: 0px;
        top: 0px;
    }
    .design-image,
    .design-image1 {
        background-size: contain;
    }
    .subscribe-header {
        gap: 10px;
    }
    .subscribe-header h4 {
        font-size: 24px;
    }
    .subscribe-header span {
        font-size: 14px;
        line-height: 16px;
        width: 400px;
    }
    .subscribe-box {
        margin-left: auto;
        margin-right: auto;
        width: 82%;
        gap: 10px;
    }
    .subscribe-form {
        gap: 3%;
        margin-left: 3%;
    }
    .subscribe-btn {
        font-size: 14px;
        line-height: 18px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .design-1 {
        width: 161px;
        height: 100px;
        position: absolute;
        bottom: -20px;
        left: 0px;
    }
    .design-2 {
        width: 161px;
        height: 107px;
        position: absolute;
        right: 0px;
        top: 0px;
    }
    .design-image,
    .design-image1 {
        background-size: contain;
    }
    .subscribe-header span {
        font-size: 16px;
    }
    .subscribe-box {
        margin-left: 22%;
        width: 60%;
    }
}

@media screen and (min-width: 992px) and (max-width: 1140px) {
    .subscribe-box {
        width: 62%;
        gap: 20px;
    }
    .subscribe-header h4 {
        font-size: 28px;
    }
    .design-image,
    .design-image1 {
        background-size: contain;
    }
    .design-1 {
        position: relative;
        bottom: 0px;
        left: 0px;
    }
    .subscribe-btn {
        font-size: 16px;
    }
}

@media screen and (min-width: 1400px) {
    .subscribe-header h4 {
        font-size: 38px;
        width: 98%;
    }
    .subscribe-header span {
        font-size: 16px;
    }
}