  @font-face {
      font-family: "Recoleta";
      src: url(Fonts/Recoleta-Regular.ttf);
  }
  
  @font-face {
      font-family: "Proxima Nova";
      src: url(Fonts/ProximaNova-Regular.ttf);
  }
  
  .join-us {
      width: 83%;
      background: linear-gradient(73.28deg, rgba(36, 36, 36, 0.75) 33.38%, rgba(248, 248, 248, 0)94.31%), url(../images/back1.png);
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 10px;
      height: 500px;
      margin-left: 9%;
      margin-bottom: 40px;
      position: relative;
  }
  
  .join-text {
      display: flex;
      flex-direction: column;
      position: absolute;
      gap: 30px;
      top: 180px;
      left: 50px;
      width: 38%;
      height: 130px;
  }
  
  .join-text p {
      width: 95%;
      font-family: 'Recoleta';
      font-style: normal;
      font-weight: 700;
      font-size: 38px;
      line-height: 55px;
      color: #FFFFFF;
      flex: none;
      order: 0;
      flex-grow: 0;
  }
  
  .new-1 {
      border-color: #FFFFFF;
  }
  
  .new {
      font-family: 'Proxima Nova';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 25px;
      color: #FFFFFF;
  }
  /* Conclase Community */
  
  .conc-community {
      display: flex;
      flex-direction: column;
      gap: 80px;
  }
  
  .conc-text {
      display: flex;
      flex-direction: column;
      gap: 45px;
      width: 650px;
      margin-left: auto;
      margin-right: auto;
      margin-top: 85px;
  }
  
  .conc-head {
      display: flex;
      flex-direction: column;
      gap: 25px;
  }
  
  .comm-head {
      font-family: 'Proxima Nova';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #777777;
      text-align: center;
  }
  
  .comm-text {
      font-family: 'Recoleta';
      font-style: normal;
      font-weight: 700;
      font-size: 48px;
      line-height: 60px;
      text-align: center;
      color: #242424;
  }
  
  .comm-sub {
      width: 650px;
      font-family: 'Proxima Nova';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 20px;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
      color: #777777;
  }
  
  .conc-buttons {
      margin-left: auto;
      margin-right: auto;
      width: 80%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
  }
  
  .size {
      width: 45%;
      margin-left: auto;
      margin-right: auto;
  }
  
  .new-border {
      border-color: #214aa0;
  }
  
  .new-color {
      color: #214aa0;
  }
  
  .btn-img1 {
      width: 25px;
      height: 25px;
      background: url("../images/join1.png");
  }
  /* Community Images */
  
  .conc-img {
      width: 89%;
      margin-left: 9.3%;
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      height: 350px;
  }
  
  .img-1 {
      width: 78%;
      height: 300px;
      background-repeat: no-repeat;
      background-size: contain;
  }
  
  .i-1 {
      background: url("../images/img1.png");
      background-repeat: no-repeat;
  }
  
  .i-2 {
      background: url("../images/img2.png");
      background-repeat: no-repeat;
      margin-top: 50px;
  }
  
  .i-3 {
      background: url("../images/img3.png");
      background-repeat: no-repeat;
  }
  
  .i-4 {
      background: url("../images/img4.png");
      background-repeat: no-repeat;
      margin-top: 50px;
  }
  
  .i-5 {
      background: url("../images/img5.png");
      background-repeat: no-repeat;
  }
  /* Engineering Crew */
  
  .crew {
      position: relative;
      display: flex;
      flex-direction: column;
      gap: 40px;
      width: 88%;
      margin-left: 9.2%;
      margin-bottom: 40px;
  }
  
  .crew-header {
      display: flex;
      flex-direction: column;
      gap: 24px;
      width: 40%;
  }
  
  .crew-head {
      font-family: 'Proxima Nova';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 25px;
      color: #777777;
  }
  
  .crew-text {
      font-family: 'Recoleta';
      font-style: normal;
      font-weight: 600;
      font-size: 36px;
      line-height: 50px;
      color: #242424;
  }
  
  .crew-sub {
      font-family: 'Proxima Nova';
      font-style: normal;
      width: 88%;
      font-weight: 400;
      font-size: 16px;
      line-height: 25px;
      color: #242424;
  }
  /* Crew Card */
  
  .crew-card {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
  }
  
  #myw {
      width: 90%;
      height: 260px;
  }
  
  #myp {
      width: 93%;
      font-size: 18px;
  }
  
  .first,
  .second,
  .third,
  .fourth,
  .fifth,
  .sixth,
  .seventh,
  .eighth,
  .ninth,
  .tenth,
  .eleventh,
  .twelveth {
      width: 54px;
      height: 54px;
      border-radius: 50%;
      flex: none;
      order: 0;
      flex-grow: 0;
  }
  
  .first {
      background: url("../images/icons/first.png");
  }
  
  .second {
      background: url("../images/icons/second.png");
  }
  
  .third {
      background: url("../images/icons/third.png");
  }
  
  .fourth {
      background: url("../images/icons/fourth.png");
  }
  
  .fifth {
      background: url("../images/icons/fifth.png");
  }
  
  .sixth {
      background: url("../images/icons/sixth.png");
  }
  
  .seventh {
      background: url("../images/icons/seventh.png");
  }
  
  .eighth {
      background: url("../images/icons/eighth.png");
  }
  
  .ninth {
      background: url("../images/icons/ninth.png");
  }
  
  .tenth {
      background: url("../images/icons/tenth.png");
  }
  
  .eleventh {
      background: url("../images/icons/eleventh.png");
  }
  
  .twelveth {
      background: url("../images/icons/twelveth.png");
  }
  /* Media Queries */
  
  @media screen and (max-width: 480px) {
      .conc-community {
          margin-bottom: -190px;
          gap: 40px;
      }
      .conc-text {
          width: 300px;
          gap: 20px;
          margin-top: 50px;
      }
      .conc-head {
          gap: 15px;
      }
      .conc-buttons {
          flex-direction: column;
      }
      .comm-text {
          font-size: 18px;
          line-height: 25px;
          width: 260px;
          margin-left: auto;
          margin-right: auto;
      }
      .comm-sub {
          font-size: 12px;
          line-height: 16px;
          width: 300px;
      }
      .size {
          width: 90%;
      }
      .conc-img {
          margin-left: 5%;
          width: 90%;
      }
      .i-1,
      .i-2,
      .i-3,
      .i-4,
      .i-5 {
          background-size: contain;
          width: 86%;
          height: 320px;
      }
      .crew {
          margin-left: 5.5%;
          gap: 20px;
          margin-bottom: 20px;
      }
      .crew-header {
          width: 280px;
          gap: 10px;
      }
      #crew-head {
          font-size: 16px;
      }
      #crew-job {
          font-size: 12px;
      }
      .crew-text {
          font-size: 18px;
          line-height: 25px;
      }
      .crew-sub {
          font-size: 12px;
          line-height: 16px;
          width: 98%;
      }
      .crew-card {
          grid-template-columns: repeat(2, 1fr);
          margin-left: -12px;
      }
      #myw {
          width: 98%;
          height: 270px;
          margin-right: -12px;
      }
      .test-name {
          gap: 0px;
          margin-top: 5px;
      }
      #myp {
          height: auto;
          width: 93%;
          font-size: 14px;
          line-height: 18px;
          top: 30px;
      }
      .first,
      .second,
      .third,
      .fourth,
      .fifth,
      .sixth,
      .seventh,
      .eighth,
      .ninth,
      .tenth,
      .eleventh,
      .twelveth {
          width: 30px;
          height: 30px;
          background-size: contain;
      }
      .join-us {
          width: 90%;
          height: 290px;
          margin-left: 5%;
      }
      .join-text {
          width: 190px;
          top: 100px;
          left: 10px;
          gap: 15px;
      }
      .join-text p {
          font-size: 18px;
          line-height: 24px;
          width: 200px;
      }
      .btn-img {
          width: 15px;
          height: 16px;
          background-size: contain;
      }
      .new {
          font-size: 10px;
      }
      .new-1 {
          gap: 1%;
          height: 40px;
      }
  }
  
  @media screen and (min-width: 481px) and (max-width: 767px) {
      .conc-community {
          margin-bottom: -170px;
          gap: 40px;
      }
      .conc-text {
          width: 440px;
          gap: 30px;
          margin-top: 50px;
      }
      .comm-text {
          font-size: 28px;
          line-height: 35px;
          width: 400px;
          margin-left: auto;
          margin-right: auto;
      }
      .comm-sub {
          font-size: 14px;
          width: 420px;
      }
      .size {
          width: 47%;
      }
      .conc-img {
          margin-left: 5%;
          width: 90%;
      }
      .i-1,
      .i-2,
      .i-3,
      .i-4,
      .i-5 {
          background-size: contain;
          width: 86%;
          height: 320px;
      }
      .crew {
          margin-left: 5%;
          gap: 20px;
          margin-bottom: 20px;
      }
      .crew-header {
          width: 450px;
          gap: 10px;
      }
      .crew-text {
          font-size: 28px;
          line-height: 35px;
      }
      .crew-card {
          grid-template-columns: repeat(2, 1fr);
      }
      #crew-head {
          font-size: 16px;
      }
      #crew-job {
          font-size: 12px;
      }
      #myw {
          width: 97%;
          height: 280px;
      }
      #myp {
          height: auto;
          width: 90%;
          font-size: 16px;
          line-height: 18px;
          top: 45px;
      }
      .join-us {
          width: 90%;
          height: 360px;
          margin-left: 5%;
      }
      .join-text {
          width: 230px;
          top: 120px;
          left: 30px;
          gap: 15px;
      }
      .join-text p {
          font-size: 28px;
          line-height: 34px;
          width: 250px;
      }
      .new {
          font-size: 14px;
      }
      .new-1 {
          gap: 3%;
      }
  }
  
  @media (min-width: 630px) and (max-width: 767px) {
      .conc-community {
          margin-bottom: -100px;
      }
      .tag-name {
          font-size: 16px;
      }
      .tag-job {
          font-size: 13px;
      }
      #myp {
          font-size: 16px;
          line-height: 20px;
      }
  }
  
  @media screen and (min-width: 768px) and (max-width: 991px) {
      .conc-community {
          margin-bottom: -50px;
      }
      .comm-text {
          font-size: 36px;
          line-height: 42px;
          width: 500px;
          margin-left: auto;
          margin-right: auto;
      }
      .comm-sub {
          font-size: 16px;
          width: 600px;
      }
      .i-1,
      .i-2,
      .i-3,
      .i-4,
      .i-5 {
          background-size: contain;
          width: 86%;
          height: 320px;
      }
      .crew-header {
          width: 450px;
      }
      #myw {
          width: 95%;
          height: 290px;
      }
      #myp {
          height: auto;
          width: 90%;
          font-size: 16px;
          line-height: 24px;
          top: 45px;
      }
      .join-us {
          width: 87%;
          height: 430px;
      }
      .join-text {
          width: 490px;
          top: 150px;
      }
      .join-text p {
          font-size: 36px;
          line-height: 45px;
          width: 350px;
      }
  }
  
  @media screen and (min-width:992px) and (max-width: 1199px) {
      .conc-text {
          margin-top: 70px;
      }
      .comm-text {
          font-size: 36px;
          line-height: 45px;
          width: 450px;
          margin-left: auto;
          margin-right: auto;
      }
      .crew {
          margin-left: 8%;
      }
      .crew-header {
          width: 50%;
          gap: 14px;
      }
      .crew-text {
          font-size: 32px;
          line-height: 45px;
      }
      #myw {
          width: 95%;
      }
      .join-text p {
          font-size: 34px;
          line-height: 48px;
      }
      .new-1 {
          width: 83%;
      }
      .new {
          font-size: 14px;
      }
  }
  
  @media screen and (min-width: 1200px) {
      .crew-header {
          width: 45%;
      }
  }
  
  @media screen and (min-width: 1400px) {
      .conc-img {
          width: 85%;
      }
      .img-1 {
          width: 89%;
      }
      .crew {
          margin-top: 20px;
          width: 86%;
      }
      .crew-sub {
          font-size: 18px;
      }
      .new {
          font-size: 18px;
      }
      .join-us {
          height: 550px;
      }
  }