@font-face {
  font-family: "Recoleta";
  src: url(Fonts/Recoleta-Regular.ttf);
}

@font-face {
  font-family: "Proxima Nova";
  src: url(Fonts/ProximaNova-Regular.ttf);
}

/* About Styling */

.about {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 565px;
  background: #214aa0;
}

.head {
  width: 48%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 60px;
}

.about-header {
  position: relative;
}

.abttxt {
  position: relative;
  margin-left: 20%;
  height: 20px;
  top: 60px;
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #f8f8f8;
}

.txt {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 19%;
  padding: 0px;
  position: relative;
  width: 75%;
  margin-top: 40px;
  gap: 10px;
}

.minor {
  width: 83%;
  margin-top: 10px;
}

.text-1 {
  width: 97%;
  font-family: "Recoleta";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 45px;
  color: #ffffff;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin-top: 25px;
}

.text-2 {
  width: 92%;
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #f8f8f8;
  flex: none;
  margin-top: 10px;
  order: 1;
  flex-grow: 0;
  word-spacing: 1px;
  z-index: 10;
}

.size-ch {
  font-size: 14px;
}

#width {
  width: 96%;
  font-size: 14px;
}

.abt-link {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 20px 100px;
  width: 50%;
  height: 50px;
  background: #fdc329;
  border-radius: 8px;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin-left: 19%;
  text-decoration: none;
}

.abt-link:hover {
  transform: scale(0.9);
}

.abt-txt {
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: #242424;
  flex: none;
  order: 0;
  flex-grow: 0;
}

/* image styling */

.image-sec {
  position: relative;
  width: 50%;
}

.abt-img {
  margin-left: 50px;
  width: 452px;
  height: 403px;
  margin-top: 85px;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("../images/groups3.png");
  border-radius: 8px;
}

.img-sec-1 {
  width: 130px;
  height: 50px;
  border-radius: 3px;
  background-color: #477dd9;
  font-size: 18px;
  color: #ffffff;
  font-family: "Proxima Nova";
  font-weight: 700;
  text-align: center;
  padding-top: 15px;
  position: absolute;
  top: 100px;
  left: -10px;
  cursor: default;
}

.img-sec-2 {
  width: 140px;
  height: 70px;
  border-radius: 3px;
  background-color: #477dd9;
  font-size: 24px;
  color: #ffffff;
  font-family: "Proxima Nova";
  font-weight: 700;
  text-align: center;
  position: absolute;
  padding-top: 5px;
  top: 270px;
  left: 450px;
  cursor: default;
}

.img-sec-2 span {
  color: #fdc329;
}

.img-sec-3 {
  width: 128px;
  height: 146px;
  background: url("../images/design/connect.png");
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  top: 310px;
  left: -30px;
}

/* About Us Page */

.frame {
  position: absolute;
  width: 470px;
  height: 368px;
  left: 53%;
  top: 83px;
  border-top: 8px solid #214aa0;
  border-right: 8px solid #214aa0;
  border-radius: 10px;
  background-color: #fff;
}

.abt-main {
  position: absolute;
  width: 480px;
  height: 378px;
  left: 51%;
  top: 98px;
  background: url("../images/groups.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 8px;
}

.about-add {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
}

.drawing-1 {
  position: absolute;
  left: 45.5%;
  top: 44px;
  width: 120px;
  height: 120px;
  border: 27px solid #7072ff;
  border-radius: 50%;
}

.drawing-2 {
  width: 160px;
  height: 160px;
  border-radius: 50%;
  background: #dde8ff;
  position: absolute;
  right: 70px;
  top: -40px;
}

.drawing-3 {
  width: 270px;
  height: 270px;
  border-radius: 50%;
  background: #e9f6f9;
  position: absolute;
  right: -40px;
  top: 320px;
}

.drawing-4 {
  width: 240px;
  height: 240px;
  border-radius: 50%;
  background: #fff6de;
  position: absolute;
  left: -70px;
  top: 280px;
}

#size {
  font-size: 16px;
  line-height: 20px;
  width: 100%;
}

#f {
  color: #fdc329;
}

#g {
  color: #214aa0;
}

.new-abt-list {
  order: 2;
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  color: #fdc329;
}

.new-abt-list li {
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  color: #242424;
}

.new-abt-list li span {
  font-weight: 700;
}

/* Mission */

.mission {
  width: 85%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  gap: 30px;
  border: 2px solid #efefef;
  border-radius: 8px;
  background-color: #ffffff;
  position: relative;
}

.mission-head {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 25px;
  justify-content: center;
  align-items: center;
}

.miss-header {
  font-family: "Recoleta";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  color: #214aa0;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin-top: 35px;
}

.miss-sub {
  width: 800px;
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #242424;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.miss-sub span {
  color: #214aa0;
  font-weight: 600;
}

.mission-card {
  display: grid;
  width: 85%;
  grid-template-columns: repeat(3, 1fr);
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
}

.miss-card {
  margin-left: 10px;
  width: 97%;
  height: 300px;
  background-color: #f8f8f8;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.miss-card-header {
  font-family: "Recoleta";
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 35px;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin-left: 30px;
  color: #242424;
}

.miss-card-text {
  width: 85%;
  margin-left: 30px;
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #777777;
}

.miss-card ul {
  width: 85%;
  margin-left: 35px;
  list-style-image: url("../images/icons/icon.png");
}

.miss-card ul li {
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #777777;
  margin-bottom: 5px;
}

/* End of Mission */

/*What We Do */

.what-we-do {
  width: 100%;
  background-color: #214aa0;
  margin-top: 35px;
  position: relative;
  height: auto;
  padding-bottom: 60px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.what-header {
  width: 40%;
  margin-left: 9.5%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 40px;
}

.what-head {
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #f8f8f8;
}

.what-sub {
  font-family: "Recoleta";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 40px;
  color: #ffffff;
  width: 250px;
}

.what-text {
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #f8f8f8;
  width: 450px;
}

.what-card {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 82%;
  margin-left: 9%;
}

.what {
  width: 93%;
  height: 320px;
  background: #2d54a7;
  display: flex;
  flex-direction: column;
  gap: 15px;
  position: relative;
  text-decoration: none;
}

.what-card-head {
  font-family: "Recoleta";
  font-weight: 600;
  font-size: 28px;
  line-height: 38px;
  color: #fdc329;
  margin-left: 24px;
}

.what-card-text {
  width: 88%;
  font-family: "Proxima Nova";
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #d0d0d0;
  margin-left: 24px;
}

.what:hover {
  border: 2px solid #fdc329;
}

.what-img1 {
  width: 45px;
  height: 45px;
  background: url("../images/design/what1.png");
  background-size: contain;
  background-repeat: no-repeat;
  margin-left: 24px;
  margin-top: 24px;
}

.what-img2 {
  width: 45px;
  height: 45px;
  background: url("../images/design/what2.png");
  background-size: contain;
  background-repeat: no-repeat;
  margin-left: 24px;
  margin-top: 24px;
}

.what-img3 {
  width: 45px;
  height: 45px;
  background: url("../images/design/what3.png");
  background-size: contain;
  background-repeat: no-repeat;
  margin-left: 24px;
  margin-top: 24px;
}

.what-img4 {
  width: 45px;
  height: 45px;
  background: url("../images/design/what4.png");
  background-size: contain;
  background-repeat: no-repeat;
  margin-left: 24px;
  margin-top: 24px;
}

/* Team */

.team {
  position: relative;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 0px;
}

.team-color {
  color: #777777;
  margin-bottom: 10px;
}

.team-style {
  color: #242424;
  margin-bottom: 10px;
}

.curve {
  width: 92%;
  height: 370px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
}

.team-sec {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  width: 82%;
  margin-left: 9%;
}

.team-card {
  width: 100%;
  height: 320px;
  position: relative;
  border-radius: 10px;
}

.team-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: 1s;
  z-index: 1;
  border-radius: 10px;
}

.curve:hover .team-image {
  opacity: 0;
  transition: 0.3s;
}

.team-text {
  width: 88%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 25px;
  font-size: 18px;
  color: #ffffff;
  font-family: "Proxima Nova";
}

.team-span {
  display: flex;
  flex-direction: row;
  gap: 10px;
  height: 40px;
  margin-left: 5px;
}

.team-icon {
  display: none;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.team-head p {
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  color: #242424;
}

#hide {
  display: none;
}

.curve:hover .team-span .team-icon {
  display: block;
}

.curve:hover #hide {
  display: block;
  font-size: 14px;
  font-weight: 400;
}

#team-1 {
  background: url("../images/icons/james.png");
  background-size: contain;
}

#team-2 {
  background: url("../images/icons/log1.png");
  background-size: contain;
}

#team-3 {
  background: url("../images/icons/khauthar.png");
  background-size: contain;
}

#team-4 {
  background: url("../images/icons/jumai.png");
  background-size: contain;
}

#team-5 {
  background: url("../images/icons/posi.png");
  background-size: contain;
}

#team-6 {
  background: url("../images/icons/dami.png");
  background-size: contain;
}

#team-7 {
  background: url("../images/icons/alhadi.png");
  background-size: contain;
}

#team-8 {
  background: url("../images/icons/prudence.png");
  background-size: contain;
}

.space {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

/* Media Queries */

@media (max-width: 480px) {
  .about {
    flex-direction: column;
    height: 700px;
  }
  .head {
    width: 93%;
    margin-bottom: 20px;
    gap: 30px;
  }
  .txt {
    width: 95%;
    margin-left: 7%;
    gap: 5px;
    margin-top: 10px;
  }
  .text-1 {
    font-size: 24px;
    line-height: 30px;
  }
  .space {
    height: auto;
    gap: 0px;
  }
  #width {
    font-size: 12px;
    line-height: 16px;
    width: 98%;
  }
  .abt-link {
    margin-left: 7%;
    margin-top: -20px;
    width: 20%;
    padding: 10px 60px;
    height: 40px;
  }
  .abt-txt {
    font-size: 14px;
  }
  .image-sec {
    width: 100%;
  }
  .abt-img {
    width: 244px;
    height: 224px;
    background-size: cover;
    margin-left: 30px;
    margin-top: 0px;
  }
  .img-sec-1 {
    height: 40px;
    width: 90px;
    font-size: 12px;
    left: 10px;
    top: 5px;
  }
  .img-sec-2 {
    width: 90px;
    height: 55px;
    font-size: 16px;
    top: 95px;
    left: 220px;
  }
  .img-sec-3 {
    width: 72px;
    height: 88px;
    left: 5px;
    top: 125px;
  }
  /* About Page */
  .about-add {
    flex-direction: column;
    height: 980px;
  }
  .drawing-1,
  .drawing-2,
  .drawing-3,
  .drawing-4 {
    display: none;
  }
  #text-add {
    width: 270px;
    font-size: 24px;
    line-height: 30px;
  }
  #size {
    width: 95%;
    line-height: 18px;
    font-size: 14px;
  }
  .abt-main {
    width: 250px;
    height: 250px;
    top: 690px;
    left: 13%;
  }
  .frame {
    width: 250px;
    height: 250px;
    top: 675px;
    left: 17%;
  }
  .mission {
    width: 97%;
  }
  .mission-head {
    gap: 20px;
  }
  .miss-sub {
    font-size: 12px;
    width: 290px;
    line-height: 16px;
  }
  .mission-card {
    width: 95%;
    grid-template-columns: repeat(1, 1fr);
  }
  .miss-card {
    height: 280px;
    width: 98%;
    margin-left: 0px;
    margin-bottom: 20px;
  }
  .short {
    height: 200px;
  }
  .miss-header {
    font-size: 26px;
    line-height: 30px;
  }
  .miss-card-header {
    margin-left: 20px;
    font-size: 20px;
    line-height: 24px;
  }
  .miss-card-text {
    font-size: 16px;
    line-height: 18px;
    margin-left: 20px;
    width: 260px;
  }
  .miss-card ul {
    width: 75%;
  }
  .miss-card ul li {
    font-size: 14px;
    line-height: 18px;
    margin-left: -5px;
    width: 260px;
  }
  .what-we-do {
    padding-bottom: 40px;
  }
  .what-header {
    margin-left: 7%;
  }
  .what-sub {
    font-size: 24px;
    line-height: 28px;
    width: 190px;
  }
  .what-text {
    font-size: 14px;
    line-height: 18px;
    width: 280px;
  }
  .what-card {
    width: 93%;
    grid-template-columns: repeat(1, 1fr);
    margin-left: auto;
    margin-right: auto;
  }
  .what-img1,
  .what-img2,
  .what-img3,
  .what-img4 {
    width: 30px;
    height: 30px;
    margin-top: 20px;
    margin-left: 10px;
  }
  .what-card-head {
    font-size: 16px;
    line-height: 20px;
    margin-left: 10px;
  }
  .what-card-text {
    margin-left: 10px;
    font-size: 12px;
    width: 85%;
  }
  .what {
    margin-bottom: 20px;
    gap: 10px;
    width: 100%;
    margin-right: 5px;
    height: auto;
    padding-bottom: 40px;
  }
  .curve {
    height: 250px;
    width: 98%;
    margin-right: 5px;
  }
  .teams {
    width: 92%;
    margin-left: auto;
    margin-right: auto;
    grid-template-columns: repeat(2, 1fr);
  }
  .team-card {
    height: 180px;
  }
  .team-text {
    margin-top: 20px;
    font-size: 16px;
  }
  .team-span {
    gap: 5px;
  }
  .team-icon {
    width: 30px;
    height: 30px;
  }
  .curve:hover #hide {
    font-size: 12px;
  }
  .team-text {
    font-size: 16px;
  }
}

@media (min-width: 300px) and (max-width: 480px) {
  .image-sec {
    margin-left: 4%;
  }
  .about-add {
    height: 1070px;
  }
  .abt-main {
    top: 760px;
    left: 13%;
  }
  .frame {
    top: 745px;
    left: 17%;
  }
}

@media (min-width: 370px) and (max-width: 480px) {
  .image-sec {
    margin-left: 6%;
  }
}

@media (min-width: 390px) and (max-width: 480px) {
  .about-add {
    height: 980px;
  }
  .abt-main {
    top: 690px;
    left: 13%;
  }
  .frame {
    top: 675px;
    left: 17%;
  }
}

@media (min-width: 430px) and (max-width: 480px) {
  .about-add {
    height: 910px;
  }
  .frame {
    top: 595px;
  }
  .abt-main{
    top: 610px;
  }
}

@media (min-width: 390px) and (max-width: 480px) {
  .image-sec {
    margin-left: 11%;
  }
}

@media (min-width: 420px) and (max-width: 480px) {
  .about {
    height: 660px;
  }
  .image-sec {
    margin-left: 13%;
  }
}

@media (min-width: 450px) and (max-width: 480px) {
  .image-sec {
    margin-left: 17%;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  .about {
    flex-direction: column;
    height: 845px;
  }
  .head {
    width: 93%;
    margin-bottom: 20px;
    gap: 30px;
  }
  .txt {
    width: 95%;
    margin-left: 7%;
    gap: 10px;
    margin-top: 20px;
  }
  .text-1 {
    font-size: 28px;
    line-height: 30px;
  }
  .space {
    height: auto;
  }
  .abt-link {
    margin-left: 7%;
    margin-top: -15px;
    width: 20%;
  }
  .abt-txt {
    font-size: 16px;
  }
  .image-sec {
    margin-left: 7%;
    width: 100%;
  }
  .abt-img {
    width: 344px;
    height: 324px;
    background-size: cover;
    margin-left: 25px;
    margin-top: 0px;
  }
  .img-sec-1 {
    height: 40px;
    width: 110px;
    font-size: 12px;
    left: 0px;
    top: 20px;
  }
  .img-sec-2 {
    width: 120px;
    height: 55px;
    font-size: 18px;
    top: 150px;
    left: 300px;
  }
  .img-sec-3 {
    width: 82px;
    height: 98px;
    left: -10px;
    top: 210px;
  }
  /* About Page */
  .about-add {
    flex-direction: column;
    height: 1000px;
  }
  .drawing-1,
  .drawing-2,
  .drawing-3,
  .drawing-4 {
    display: none;
  }
  #text-add {
    width: 340px;
    font-size: 32px;
    line-height: 34px;
  }
  #size {
    width: 95%;
    line-height: 18px;
  }
  .abt-main {
    width: 360px;
    height: 330px;
    top: 630px;
    left: 11%;
  }
  .frame {
    width: 360px;
    height: 300px;
    top: 610px;
    left: 14%;
  }
  .mission {
    width: 97%;
  }
  .mission-head {
    gap: 20px;
  }
  .miss-sub {
    font-size: 14px;
    width: 400px;
    line-height: 18px;
  }
  .mission-card {
    width: 99%;
  }
  .miss-card {
    height: 280px;
    width: 98%;
    margin: 0px;
  }
  .miss-card-header {
    margin-left: 10px;
    font-size: 20px;
    line-height: 24px;
  }
  .miss-card-text {
    font-size: 14px;
    line-height: 16px;
    margin-left: 10px;
  }
  .miss-card ul {
    width: 75%;
  }
  .miss-card ul li {
    font-size: 14px;
    line-height: 16px;
    margin-left: -5px;
  }
  .what-header {
    margin-left: 7%;
  }
  .what-sub {
    font-size: 26px;
    line-height: 32px;
    width: 200px;
  }
  .what-text {
    line-height: 18px;
    width: 400px;
  }
  .what-card {
    grid-template-columns: repeat(1, 1fr);
    margin-left: 7%;
  }
  .what-img1,
  .what-img2,
  .what-img3,
  .what-img4,
  .what-card-head,
  .what-card-text {
    margin-left: 20px;
  }
  .what {
    margin-bottom: 20px;
    gap: 10px;
  }
  .team {
    margin-bottom: -40px;
  }
  .curve {
    height: 340px;
    margin-bottom: 15px;
  }
  .team-sec{
    grid-template-columns: repeat(2,1fr);
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 610px) and (max-width: 767px) {
  .about-add {
    height: 950px;
  }
  .abt-main {
    top: 550px;
    left: 20%;
  }
  .frame {
    top: 530px;
    left: 23%;
  }
  .miss-card {
    height: 300px;
  }
}

@media (min-width: 710px) and (max-width: 767px) {
  .frame{
    top: 470px;
  }
  .abt-main{
    top: 490px;
  }
  .image-sec {
    margin-left: 20%;
  }
  .about-add{
    height: 870px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .about {
    flex-direction: column;
    height: 940px;
  }
  .head {
    width: 93%;
    margin-bottom: 20px;
    gap: 30px;
  }
  .txt {
    width: 95%;
    margin-left: 10%;
    gap: 10px;
    margin-top: 20px;
  }
  #width {
    width: 95%;
    font-size: 16px;
  }
  .abt-link {
    margin-left: 10%;
    width: 30%;
  }
  .image-sec {
    margin-left: 15%;
  }
  .abt-img {
    margin-top: 10px;
  }
  .img-sec-1 {
    top: 40px;
  }
  .img-sec-2 {
    top: 200px;
  }
  .img-sec-3 {
    top: 260px;
  }
  /* About Page */
  .head-add {
    width: 48%;
    gap: 10px;
    margin-bottom: 30px;
  }
  #text-add {
    width: 330px;
    font-size: 32px;
    line-height: 34px;
  }
  #size {
    width: 95%;
    line-height: 18px;
  }
  .abt-main {
    width: 360px;
    height: 330px;
  }
  .frame {
    width: 360px;
    height: 300px;
  }
  .mission-head {
    gap: 20px;
  }
  .miss-sub {
    font-size: 16px;
    width: 600px;
    line-height: 20px;
  }
  .mission-card {
    width: 95%;
  }
  .miss-card {
    height: 340px;
  }
  .miss-card-header {
    margin-left: 20px;
  }
  .miss-card-text {
    font-size: 14px;
    line-height: 18px;
    margin-left: 20px;
  }
  .miss-card ul {
    width: 80%;
  }
  .miss-card ul li {
    font-size: 14px;
    line-height: 18px;
    margin-left: -5px;
  }
  .what-card {
    width: 90%;
    margin: 0 auto;
  }
  .what {
    margin-bottom: 20px;
    width: 96%;
  }
  .team {
    margin-bottom: -20px;
  }
  .curve {
    height: 310px;
    margin-bottom: 10px;
  }
  .team-sec{
    width: 90%;
    margin: 0 auto;
  }
  .what-header{
    width: 90%;
    margin: 30px auto;
  }
}

@media (min-width: 850px) and (max-width: 991px) {
  .image-sec {
    margin-left: 20%;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .about {
    height: auto;
  }
  .txt {
    margin-left: 17.5%;
    gap: 5px;
  }
  .head {
    width: 49%;
  }
  .text-1 {
    font-size: 32px;
    line-height: 38px;
  }
  #width {
    width: 100%;
    line-height: 18px;
  }
  .abt-link {
    margin-left: 17.5%;
    width: 7%;
    height: 40px;
  }
  .abt-img {
    margin-left: 0px;
    width: 420px;
    height: 370px;
  }
  .image-sec {
    margin-top: 5px;
    margin-left: 20px;
  }
  .img-sec-1 {
    width: 115px;
    font-size: 16px;
    left: -40px;
  }
  .img-sec-2 {
    width: 125px;
    font-size: 22px;
    top: 250px;
    left: 360px;
  }
  .img-sec-3 {
    width: 108px;
    height: 126px;
    left: -40px;
    top: 330px;
  }
  /*About Media */
  .about-add {
    margin-bottom: 40px;
  }
  .text-2 {
    font-size: 13px;
    width: 100%;
  }
  /* Mission */
  .mission-head {
    gap: 15px;
  }
  .miss-header {
    font-size: 28px;
    line-height: 34px;
  }
  .miss-sub {
    width: 700px;
    font-size: 16px;
    line-height: 22px;
  }
  .miss-card {
    height: 300px;
  }
  .miss-card-header {
    height: 65px;
    font-size: 24px;
    line-height: 30px;
  }
  /* What We Do */
  /* Team */
  /* .team-text {
        font-size: 14px;
    } */
}

@media screen and (min-width: 992px) and (max-width: 1140px) {
  #width {
    width: 100%;
    line-height: 18px;
  }
  .image-sec {
    margin-left: 10px;
  }
  /* Mission */
  .text-1 {
    font-size: 32px;
    line-height: 38px;
  }
  #size {
    line-height: 18px;
  }
  .mission-card {
    width: 89%;
  }
  .miss-card-text {
    line-height: 18px;
  }
  /* What We Do */
  .what-header {
    margin-left: 8%;
  }
  .what-card {
    margin-left: 8%;
    width: 90%;
  }
  .what {
    width: 95%;
    gap: 10px;
  }
  .what-img1,
  .what-img2,
  .what-img3,
  .what-img4,
  .what-card-head,
  .what-card-text {
    margin-left: 14px;
  }
  .what-icon,
  .what-icon-1 {
    margin-top: 20px;
  }
}

@media screen and (min-width: 1350px) and (max-width: 1399px) {
  .img-sec-2 {
    margin-right: 10%;
  }
}

@media screen and (min-width: 1400px) {
  #width {
    font-size: 16px;
  }
  .abt-link {
    width: 45%;
  }
  .abt-img {
    width: 480px;
    height: 420px;
  }
  .img-sec-1 {
    width: 150px;
    height: 70px;
    font-size: 20px;
    padding-top: 20px;
    top: 80px;
    left: -10px;
  }
  .img-sec-2 {
    width: 160px;
    height: 90px;
    font-size: 26px;
    padding-top: 10px;
    top: 270px;
    margin-right: 6%;
  }
  .img-sec-3 {
    width: 148px;
    height: 166px;
    top: 310px;
    left: -20px;
  }
  #size {
    line-height: 22px;
  }
  .text-1 {
    width: 90%;
    font-size: 40px;
  }
}

@media screen and (min-width: 1500px) {
  .img-sec-2 {
    margin-right: 11%;
  }
}
