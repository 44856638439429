@font-face {
    font-family: "Recoleta";
    src: url(Fonts/Recoleta-Regular.ttf);
}

@font-face {
    font-family: "Proxima Nova";
    src: url(Fonts/ProximaNova-Regular.ttf);
}

.border {
    border-bottom: 82px solid #f2f0f0;
}

.contact-wrapper {
    height: auto;
    color: black;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.touch {
    display: flex;
    width: 40%;
    height: 80px;
    flex-direction: column;
    gap: 20px;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    justify-content: center;
    margin-top: 60px;
    order: 0;
}

.first-text {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 25px;
    color: #777777;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.head-text {
    font-family: 'Recoleta';
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 65px;
    text-align: center;
    color: #242424;
}

.sec-text {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 25px;
    text-align: center;
    color: #242424;
    flex: none;
    order: 2;
    flex-grow: 0;
}

.touch-header {
    display: flex;
    flex-direction: column;
}

.underline {
    margin-left: 50px;
    width: 280px;
    height: 13px;
    background: url("../images/underline.png");
}


/* Contact Form */

.contact-form {
    width: 88%;
    height: 640px;
    margin-left: auto;
    margin-right: auto;
    order: 1;
    flex: none;
    border: 1px solid #efefef;
    margin-bottom: 20px;
    background: #ffffff;
    z-index: 10;
}

.sub-form {
    width: 90%;
    display: flex;
    flex-direction: row;
    gap: 7%;
    height: 550px;
    margin-top: 50px;
    margin-left: auto;
    margin-right: auto;
}

.contact-img {
    width: 48%;
    height: 540px;
    background: url("../images/contact.png");
    background-repeat: no-repeat;
    background-size: cover;
}

.form-card {
    margin-left: 20px;
    width: 45%;
    height: 470px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.form-head {
    width: 350px;
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    color: #777777;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.send-form {
    display: flex;
    flex-direction: column;
}

label {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #242424;
    margin-bottom: 5px;
}

.cont-form,
.cont-form-1,
.cont-form-2 {
    width: 90%;
    padding-left: 10px;
    background: #F8F8F8;
    border: 1px solid #f8f8f8;
    border-radius: 4px;
    margin-bottom: 10px;
}

.cont-form {
    height: 40px;
}

.cont-form-1 {
    padding-top: 15px;
    padding-bottom: 70px;
}

.cont-form-2 {
    padding-top: 15px;
    padding-bottom: 40px;
}

::placeholder {
    color: rgba(153, 153, 153, 0.5);
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
}

.form-btn {
    width: 90%;
    height: 40px;
    background: #FDC329;
    border: 1px solid #FDC329;
    border-radius: 4px;
    cursor: pointer;
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    color: #242424;
}


/* Design */

.d-1,
.d-2,
.d-3,
.d-4,
.d-5 {
    position: absolute;
}

.d-1 {
    width: 14.6%;
    height: 300px;
    left: 0px;
    top: 80px;
    background: url("../images/ellipse.png");
    background-repeat: no-repeat;
    background-size: contain;
}

.d-2 {
    width: 39%;
    height: 240px;
    left: 0px;
    top: -55px;
    background: url("../images/group1.png");
    background-repeat: no-repeat;
    background-size: contain;
}

.d-3 {
    width: 7%;
    height: 108px;
    left: 230px;
    top: -45px;
    background: url("../images/group2.png");
    background-repeat: no-repeat;
    background-size: contain;
}

.d-4 {
    width: 34%;
    height: 200px;
    left: 67%;
    top: -55px;
    background: url("../images/group3.png");
    background-repeat: no-repeat;
    background-size: contain;
}

.d-5 {
    width: 7%;
    height: 108px;
    left: 96.5%;
    top: -45px;
    background: url("../images/group4.png");
    background-repeat: no-repeat;
    background-size: contain;
}

.error {
    color: red;
    font-size: 14px;
    font-family: 'Proxima Nova';
}

.form-btn:hover {
    transform: scale(0.9);
    transition: 0.3s ease;
}


/* Media Query */

@media screen and (max-width: 480px) {
    .contact-wrapper {
        gap: 30px;
        margin-bottom: -20px;
    }
    .head-text {
        font-size: 36px;
        line-height: 40px;
    }
    .underline {
        width: 250px;
        margin-left: 20px;
    }
    .d-2,
    .d-4 {
        display: none;
    }
    .d-3,
    .d-5 {
        top: -10px;
    }
    .touch {
        margin-top: 35px;
    }
    .contact-form {
        height: 950px;
    }
    .sub-form {
        flex-direction: column;
        height: auto;
        margin-top: 30px;
    }
    .form-card {
        width: 100%;
        margin-left: 0px;
    }
    .cont-form,
    .cont-form-1,
    .cont-form-2,
    .form-btn {
        width: 97%;
    }
    .form-head {
        font-size: 16px;
        line-height: 20px;
        width: 280px;
    }
    .contact-img {
        width: 97%;
        margin-top: 70px;
        height: 350px;
    }
}

@media screen and (min-width: 481px) and (max-width: 767px) {
    .contact-wrapper {
        gap: 30px;
    }
    .head-text {
        font-size: 36px;
        line-height: 40px;
    }
    .underline {
        width: 250px;
        margin-left: 20px;
    }
    .d-2,
    .d-4 {
        display: none;
    }
    .d-3,
    .d-5 {
        top: -10px;
    }
    .touch {
        margin-top: 35px;
    }
    .contact-form {
        height: 1030px;
    }
    .sub-form {
        flex-direction: column;
        height: auto;
    }
    .form-card {
        width: 100%;
    }
    .contact-img {
        width: 90.5%;
        margin-top: 70px;
        margin-left: auto;
        margin-right: auto;
        height: 400px;
    }
}

@media screen and (min-width: 540px) and (max-width: 767px) {
    .contact-img {
        width: 97%;
    }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    .head-text {
        font-size: 42px;
        line-height: 50px;
    }
    .d-2,
    .d-4 {
        display: none;
    }
    .form-head {
        font-size: 16px;
        width: 300px;
    }
    .cont-form,
    .cont-form-1,
    .cont-form-2,
    .form-btn {
        width: 97%;
    }
    .contact-img {
        width: 50%;
    }
}

@media screen and (min-width:1400px) {
    .form-head {
        font-size: 20px;
        width: 400px;
    }
    label {
        font-size: 18px;
    }
    .cont-form,
    .cont-form-1,
    .cont-form-2,
    .form-btn {
        width: 95%;
    }
     ::placeholder {
        font-size: 18px;
    }
}